import type { Context } from "@nuxt/types";
import jsonwebtoken, {
  JsonWebTokenError,
  TokenExpiredError,
} from "jsonwebtoken";
import { assoc, mapObjIndexed, pipe, prop, replace } from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { RootState } from "./index";
import { AppCookieNames } from "~/constants/ecomApi";
import {
  addAccountCustomer,
  AddAccountCustomerPayload,
  fetchAccountCustomers,
  removeAccountCustomer,
} from "~/services/account.service";
import { AccountCustomer } from "~/services/account.service.d";
import type { CustomerApiError } from "~/services/customers.d";
import {
  fetchCustomerAccounts,
  fetchCustomerById,
} from "~/services/customers.service";
import { ContactPreferences, Customer } from "~/services/customers.service.d";
import { camelizeKeys } from "~/utils/utils";

dayjs.extend(isSameOrAfter);

export enum CustomerTypes {
  Admin = 1,
  Employee = 2,
}

export enum AccountTypes {
  Trade = "Trade Credit",
}

export interface AccountCredit {
  creditLimit: string;
  creditBorrowed: string;
  creditRemaining: string;
  creditPercentageUsed: string;
}

export type AccountCreditNumericalised = Record<keyof AccountCredit, number>;

export interface SpendRequirements {
  discount: string;
  minimumMonthlySpend: string;
  needToSpend: string;
  tier: string;
}

export interface SpendStats {
  estimatedSavingsLastMonth: string;
  estimatedSavingsThisMonth: string;
  lastMonthSpend: string;
  thisMonthSpend: string;
  spendRequirements: {
    standard: SpendRequirements;
  };
}

export interface Account {
  id: number;
  name: string;
  number: string;
  customerType: CustomerTypes;
  accountCreatedBy: string;
  credit?: AccountCredit;
}

export interface AuthStateUser
  extends Pick<
    AccountCustomer,
    | "employeeOrderSpendLimit"
    | "employeeMonthlySpendLimit"
    | "employeePurchaseOrderRequired"
  > {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  accountType: string;
  contactPreferences?: ContactPreferences;
  primaryAddress?: {
    id: string;
    line1: string;
    line2: string;
    line3: string;
    town: string;
    postcode: string;
  };
  accounts?: Account[];
  cardholders?: AccountCustomer[];
  token?: string;
  spendStats?: SpendStats;
  loyaltyClubMember?: boolean;
  email?: string;
  company?: string | null;
}
interface AuthState {
  user: AuthStateUser | null;
  loginModalVisible: boolean;
  redirectUrl: string | null;
  jwt: string | null;
}

export const state = (): AuthState => ({
  user: null,
  loginModalVisible: false,
  redirectUrl: null,
  jwt: null,
});

export const mutations: MutationTree<AuthState> = {
  setUser: (state: AuthState, user: AuthStateUser): void => {
    state.user = camelizeKeys(user);
  },
  setUserAccounts: (state: AuthState, accounts: Account[]): void => {
    state.user = camelizeKeys({ ...state.user, accounts });
  },
  setCardholders: (state: AuthState, cardholders: AccountCustomer[]): void => {
    if (state.user) state.user = assoc("cardholders", cardholders, state.user);
  },
  // @todo delete this this soon when encrypted cookies disappear
  setJwt: (state: AuthState, jwt: string): void => {
    state.jwt = jwt;
  },
  deleteUser: (state: AuthState): void => {
    state.user = null;
  },
  setLoginModalVisibility: (state: AuthState, visibility: boolean): void => {
    state.loginModalVisible = visibility;
  },
  setRedirectUrl: (state: AuthState, url: string): void => {
    state.redirectUrl = url;
  },
};

export const getters: GetterTree<AuthState, RootState> = {
  // @todo delete this soon when encrypted cookies disappear
  jwt: prop("jwt"),
  get: (state: AuthState): AuthState => state,
  getUser: (state: AuthState): AuthStateUser | null => state.user,
  getAccount: (state: AuthState): Account | undefined =>
    state.user?.accounts?.[0],
  getAccounts: (state: AuthState): Account[] | undefined =>
    state.user?.accounts,
  getAccountCreditInformation: (_state: AuthState, getters) =>
    getters.getAccount?.credit ?? {
      creditPercentageUsed: "0.00",
      creditRemaining: "0.00",
      creditLimit: "0.00",
      creditBorrowed: "0.00",
    },
  getAccountCreditInformationNumericalised: (_state: AuthState, getters) =>
    mapObjIndexed(
      pipe(replace(/,/g, ""), Number),
      getters.getAccount?.credit
    ) ?? {
      creditPercentageUsed: 0.0,
      creditRemaining: 0.0,
      creditLimit: 0.0,
      creditBorrowed: 0.0,
    },
  getCardHolders: (state: AuthState, getters) =>
    state.user?.cardholders?.filter(
      (cardholder) => cardholder.id !== getters.getUserId
    ) ?? [],
  isAdminAccount: (state: AuthState): boolean =>
    state.user?.accounts?.[0]?.customerType === CustomerTypes.Admin,
  // 🐛 we're expecting this to be a string in most places but it's typed as a number?
  getAccountId: (_: AuthState, getters): string | undefined =>
    getters.getAccount?.id ? String(getters.getAccount?.id) : undefined,
  getUserId: (state: AuthState): string | null => state.user?.id ?? null,
  isAuthenticated: (state: AuthState): boolean => !(state.user == null),
  isNotAuthenticated: (_s, getters) => !getters.isAuthenticated,
  loginModalVisible: prop("loginModalVisible"),
  getRedirectUrl: prop("redirectUrl"),
  isTradeAccount: (state: AuthState): Boolean =>
    state.user?.accountType === AccountTypes.Trade,
  isLoyaltyClubAccount: (state: AuthState): Boolean =>
    state.user?.loyaltyClubMember === true,
  getUserSpendStats: (_s: AuthState, getters): SpendStats | undefined =>
    getters.getUser?.spendStats,
  loyaltyClubDiscountMessagingIsActive: (_s, getters) => {
    if (getters.isTradeAccount) return false;

    if (getters.isLoyaltyClubAccount) {
      const isAfterOrFirsDaytOfNextCalendarMonth = dayjs(Date()).isSameOrAfter(
        dayjs(getters.getUser?.loyaltyClubMemberSince)
          .add(1, "month")
          .set("date", 1)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );

      return isAfterOrFirsDaytOfNextCalendarMonth;
    } else {
      return true;
    }
  },
  loyaltyClubDiscountIsActive: (_s, getters) => {
    const isAfterGracePeriod = dayjs(Date()).isSameOrAfter(
      dayjs(getters.getUser?.loyaltyClubMemberSince)
        .add(2, "month")
        .set("date", 1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
    );

    const lastMonthSpendTargetMet =
      Number(getters.getUserSpendStats?.lastMonthSpend) >=
      Number(
        getters.getUserSpendStats?.spendRequirements?.standard
          ?.minimumMonthlySpend
      );

    return isAfterGracePeriod ? lastMonthSpendTargetMet : true;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  openLoginModal({ commit }, { redirectUrl }: { redirectUrl: string }) {
    commit("setLoginModalVisibility", true);
    commit("setRedirectUrl", redirectUrl);
  },
  closeLoginModal({ commit }) {
    commit("setLoginModalVisibility", false);
  },
  async setAuthenticatedUser(
    { commit },
    { app, route, $config, $axios, $log }: Context
  ) {
    if (route.query.jwttoken) {
      app.$cookies.set(
        AppCookieNames.CustomerJWT,
        { value: route.query.jwttoken as string },
        {
          httpOnly: true,
        }
      );
    }

    const customerJwt = app.$cookies.get(AppCookieNames.CustomerJWT) || {
      value: app.$cookies.get(AppCookieNames.GuestCustomerJWT),
    }; // transient guest customer state
    if (!customerJwt) return;

    try {
      const jwt = customerJwt?.value as string;

      commit("ecomApi/setCustomerJwtToken", jwt, { root: true });

      const customerId = (jsonwebtoken.verify(jwt, $config.jwtSecret) as any)
        .customer_id;

      // Valid JWT Token so set header for authorised EcomApi axios calls
      $axios.setHeader("X-Toolstation-Customer-Id", jwt);

      await commit("setUser", await fetchCustomerById($axios, customerId));
    } catch (error: any) {
      if (error instanceof TokenExpiredError) {
        $log.warn("🚨 Expired JWT Token");
      } else if (error instanceof JsonWebTokenError) {
        $log.debug(
          `🚨 Invalid JWT Token - cause "${
            error.cause?.message ?? "unknown"
          }", message: "${error.message}", name: "${error.name}"`
        );
      } else if (
        error instanceof Error &&
        "response" in error &&
        "config" in error
      ) {
        // deal with API error?
        const apiError = error as CustomerApiError;
        $log.error(`🚨 API Error: ${apiError.response.data as string}`);
        $log.error(`🚨 Status: ${apiError.response.status as string}`);
        $log.error(`🚨 StatusText: ${apiError.response.statusText as string}`);
        $log.error(`🚨 URL: ${apiError.config.url as string}`);
      } else {
        $log.error(`🚨 ERROR: ${error as string}`);
      }

      app.$cookies.remove(AppCookieNames.CustomerJWT);
    }
  },

  async fetchAccounts({ commit, rootGetters }) {
    const user = rootGetters["auth/getUser"];
    if (user) {
      commit(
        "setUserAccounts",
        await fetchCustomerAccounts(this.$axios, user.id)
      );
    }
  },

  async fetchCardholders({ commit, getters }) {
    if (getters.isAdminAccount) {
      commit(
        "setCardholders",
        await fetchAccountCustomers(this.$axios, getters.getAccountId)
      );
    }
  },

  async addCardHolder(
    { getters },
    data: AddAccountCustomerPayload
  ): Promise<void> {
    return await addAccountCustomer(this.$axios, getters.getAccountId, data);
  },

  async deleteCardHolder(
    { dispatch, getters },
    customerId: AccountCustomer["id"]
  ) {
    await removeAccountCustomer(this.$axios, getters.getAccountId, customerId);
    await dispatch("fetchCardholders");
  },

  async loginUser(
    { commit }: { commit: any },
    {
      context,
      customer,
    }: {
      context: Context;
      customer: Customer;
    }
  ) {
    const jwt = customer.token;

    await commit("ecomApi/setCustomerJwtToken", jwt, { root: true });
    context.$axios.setHeader("X-Toolstation-Customer-Id", jwt);
    await commit("setUser", customer);

    // set cookie
    const maxAge = context.$config.customerJWTLifetime;
    context.app.$cookies.set(
      AppCookieNames.CustomerJWT,
      JSON.stringify({ value: customer.token }),
      { path: "/", maxAge }
    );
  },

  clearAuthenticatedUser({ commit, dispatch }, context) {
    delete context.$axios.defaults.headers.common["X-Toolstation-Customer-Id"];
    context.$cookies.remove(AppCookieNames.CustomerJWT);
    commit("trolley/clearAddressIds", null, { root: true });
    commit("ecomApi/deleteCustomerJwtToken", null, { root: true });
    commit("deleteUser");
    dispatch("trolley/clearTrolley", null, { root: true });
  },

  async logout({ dispatch }, context: Context) {
    await dispatch("clearAuthenticatedUser", context);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
