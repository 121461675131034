export interface ProductPriceMeta {
  net: number | string;
  vat: number | string;
  vat_rate: number | string;
  gross: number | string;
  eco: number | string | null;
  per_unit: number | string | null;
}
export interface ProductPrices {
  raw: ProductPriceMeta;
  formatted: ProductPriceMeta;
  was_price_raw: number | null;
  was_price_formatted: string | null;
}

export interface ProductImages {
  default: string;
  small: string;
  med: string;
  big: string;
  biggest: string;
  sq50: string;
  sq100: string;
  feature: string;
}

export interface Taxonomy {
  id: number;
  name: string;
  parent_id: number;
}

export interface ProductAttribute {
  id: number;
  key: string;
  value: string;
}

export interface ProductPromoMessage {
  message: string;
  itemSelector: string[] | null;
  groupSelector: any | null;
  discountArea: string | null;
  discountType: string | null;
  discountValues: any | null;
  discountOrder: object | null;
  discountGrouping: number | null;
  discountApply: string | null;
  discountMax: number | null;
}

export interface Product {
  code: number | string;
  name: string;
  slug: string;
  description: string;
  prices: ProductPrices | null;
  image: string;
  images: ProductImages | null;
  videos: string[] | null;
  url?: string;
  brand: string;
  packSize: string;
  packQuantity: string;
  subDepartmentId: number;
  channel: number;
  clickAndCollect: boolean;
  nextDayDelivery: boolean;
  directShip: boolean;
  latestCatalogue: string;
  catPageNo: number;
  isFreeGift: boolean;
  variations: string[] | null;
  taxonomies: Taxonomy[][] | null;
  technicalSpecAttributes: ProductAttribute[] | null;
  documentsAndManualsAttributes: ProductAttribute[] | null;
  otherAttributes: ProductAttribute[] | null;
  promoMessages?: ProductPromoMessage[] | null;
}

export enum ProductImageSizes {
  default = 25,
  small = 160,
  med = 250,
  big = 388,
  biggest = 800,
  sq50 = 50,
  sq100 = 100,
  feature = 205,
}
