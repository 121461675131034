import { NuxtAxiosInstance } from "@nuxtjs/axios";
import constants from "~/constants/ecomApi";
import type { Branch, BranchesLocation } from "~/types/branch";
import { getBranch, getBranches } from "~/services/api/branches.api";
import { get } from "~/utils/lru-cache";
import stringToSlug from "~/utils/strings-transformations";

export const getAllBranches = async (
  $axios: NuxtAxiosInstance
): Promise<{ [key: string]: Branch[] }> => {
  return await get(
    `getAllBranches`,
    async (): Promise<{ [key: string]: Branch[] }> => {
      const response = await getBranches($axios);

      return (
        response?.data
          .sort((a, b) => a.name.localeCompare(b.name))
          // group by branch name by first letter
          .reduce<any>((acc, branch) => {
            const char = branch.name.charAt(0).toUpperCase();
            if (acc[char] === undefined) {
              acc[char] = [];
            }
            acc[char].push(branch);
            return acc;
          }, {})
      );
    }
  );
};

export const getNearestBranches = async (
  $axios: NuxtAxiosInstance,
  payload: BranchesLocation
): Promise<{ data: Branch[] }> => {
  return await $axios.$post(constants.BRANCHES_NEAREST, payload);
};

export const getBranchById = async (
  $axios: NuxtAxiosInstance,
  id: string
): Promise<{ data: Branch }> => {
  return await $axios.$get(`/branches/${id}`);
};

export const getBranchBySlug = async (
  $axios: NuxtAxiosInstance,
  str: string
): Promise<Branch> => {
  const slug = stringToSlug(str);

  return await get(`getBranchBySlug.${slug}`, async (): Promise<Branch> => {
    const branches = await getAllBranches($axios);

    if (branches === null) {
      throw new Error("Branches not found");
    }

    // find branch by slug
    const branch = Object.values(branches)
      .flat()
      .find((branch) => stringToSlug(branch.name) === slug);
    if (branch === undefined) {
      throw new Error("Branch not found");
    }

    return await getBranch($axios, branch.id).then((response) => response.data);
  });
};
