// Set a TS SESSION ID cookie that lives forever even if the customer logs in/out - for tracking purposes
import crypto from "crypto";
import { AppCookieNames } from "~/constants/ecomApi";

export default function (context) {
  const { $cookies } = context;

  if (!$cookies.get(AppCookieNames.TsSessionId)) {
    $cookies.set(AppCookieNames.TsSessionId, crypto.randomUUID(), {
      expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000),
      path: "/",
      sameSite: "Lax",
    });
  }
}
