
import Vue from "vue";
import { mapGetters } from "vuex";
import LazyHydrate from "vue-lazy-hydration";
import SearchBar from "~/components/UI/search/SearchBar.vue";
import TrolleyIcon from "~/assets/svg/trolley.svg?inline";
import HardHatIcon from "~/assets/svg/hard-hat.svg?inline";
import NavItem from "~/components/layout/navbar/NavbarItem.vue";
import HamburgerIcon from "~/components/UI/icons/HamburgerIcon.vue";
import BottomNavbarConstants from "~/constants/bottomNavbar";
import KeyboardEvents from "~/constants/keyboardEvents";
import throttledScroll from "~/mixins/throttledScroll";
import TradeBrands from "~/components/layout/header/components/TradeBrands.vue";
import TrolleyPreview from "~/components/layout/header/components/TrolleyPreview.vue";
import { BottomNavbarLink } from "~/components/layout/navbar/NavbarBottomLink";
import DownArrow from "~/assets/svg/down-arrow.svg?inline";
import MapMarker from "~/assets/svg/map-marker.svg?inline";
const bottomNavbar = Vue.extend({
  name: "BottomNavbar",
  components: {
    LazyHydrate,
    SearchBar,
    TrolleyIcon,
    HardHatIcon,
    DownArrow,
    MapMarker,
    NavItem,
    TradeBrands,
    TrolleyPreview,
    HamburgerIcon,
    DepartmentsMenu: () =>
      import(
        /* webpackChunkName: "DepartmentsMenu" */ "~/components/layout/header/components/DepartmentsMenu.vue"
      ),
    MobileMenu: () =>
      import(
        /* webpackChunkName: "MobileMenu" */ "~/components/layout/navbar/mobile-menu/MobileMenu.vue"
      ),
  },
  mixins: [throttledScroll],
  props: {
    id: {
      type: String,
      default: "bottomNavbar",
    },
  },
  data: () => ({
    scrollY: 0,
    scrolledToTop: true,
    menuOpen: false,
    showTradeBrands: false,
    loadingBrands: false,
    showDepartmentsMenu: false,
    loadingDepartmentHierarchy: false,
    isTrolleyHovered: false,
    overlayForSearch: false,
    bottomHeaderFixed: {},
    lastScrollTop: 0,
    initialIsMobile: false,
  }),
  async fetch() {
    await this.fetchDepartments();

    if (window.innerWidth < 1025) {
      this.initialIsMobile = true;
    }
  },
  fetchOnServer: false,
  computed: {
    ...mapGetters("trolley", [
      "trolleyItemsCount",
      "trolleyLinesTotal",
      "trolleyTotal",
    ]),
    ...mapGetters("locale", ["getDepartmentsHierarchy", "getTopTradeBrands"]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("guest", ["getGuestCustomerJwtToken"]),
    ...mapGetters("viewport", ["isMobile", "isDesktop", "initialUpdate"]),
    ...mapGetters("branch", ["selectedBranchName", "isSelectedBranch"]),
    isTrolleyVisible(): boolean {
      return this.trolleyItemsCount && this.trolleyItemsCount > 0;
    },
    mobileMenuOpen(): boolean {
      return this.$store.state.mainMenu.mobileMenuOpen;
    },
    currentUserLink(): BottomNavbarLink {
      return this.getUser && !this.getGuestCustomerJwtToken
        ? this.links.myAccount
        : this.links.signIn;
    },
    links(): { [key: string]: BottomNavbarLink } {
      return BottomNavbarConstants;
    },
    trolleyItemCountFormatted(): string | number {
      return this.trolleyItemsCount < 100 ? this.trolleyItemsCount : "99+";
    },
    trolleyTotalFormatted(): string {
      if (this.trolleyTotal && this.trolleyTotal.slice(1) !== "0.00") {
        return this.trolleyTotal;
      }
      return this.$t(this.links.trolley.translationKey).toString();
    },
  },
  watch: {
    scrollY(val: number): void {
      this.scrolledToTop = val <= (this.isDesktop ? 40 : 0);
      if (Math.abs(this.lastScrollTop - val) <= 100 && !this.scrolledToTop)
        return;
      if (val > this.lastScrollTop) {
        if (val > 140)
          this.bottomHeaderFixed = {
            state: true,
            top: `top: -140px`,
          };
      } else {
        this.bottomHeaderFixed = { state: true, top: `top: 0` };
        if (val <= (this.isDesktop ? 60 : 0))
          this.bottomHeaderFixed = { state: false, top: `` };
      }
      this.lastScrollTop = val;
    },
  },
  beforeMount() {
    window.addEventListener("pageshow", this.pageshowCallback);
  },
  beforeDestroy() {
    window.removeEventListener("pageshow", this.pageshowCallback);
  },
  methods: {
    async fetchDepartments(): Promise<void> {
      const departments = this.getDepartmentsHierarchy;
      if (departments == null && !this.loadingDepartmentHierarchy) {
        this.loadingDepartmentHierarchy = true;
        await this.$store
          .dispatch("locale/fetchDepartmentsHierarchy")
          .then(() => (this.loadingDepartmentHierarchy = false));
      }
    },
    handleThrottledScroll() {
      this.scrollY = window.scrollY;
    },
    onClickMobileMenu() {
      return this.$store.commit("mainMenu/TOGGLE_MOBILE_MENU_OPEN");
    },
    toggleMenuOnKeyboardEvent(event: KeyboardEvent, menu: string): void {
      if (event.key === KeyboardEvents.ENTER_KEY_CODE) {
        event.preventDefault();
        if (menu === "departments") {
          this.showDepartmentsMenu = !this.showDepartmentsMenu;
        }
        if (menu === "brands") {
          this.showTradeBrands = !this.showTradeBrands;
        }
      }
    },
    async toggleBrands(val: boolean): Promise<void> {
      const brands = this.getTopTradeBrands;
      if (brands == null && val && !this.loadingBrands) {
        this.loadingBrands = true;
        await this.$store
          .dispatch("locale/fetchBrands")
          .finally(() => (this.loadingBrands = false));
      }
      if (val) {
        this.showDepartmentsMenu = false;
      }
      this.showTradeBrands = val;
    },
    toggleDepartments(val: boolean): void {
      if (val) {
        this.showTradeBrands = false;
      }
      this.showDepartmentsMenu = val;
    },
    toggleTrolley(val: boolean): void {
      this.isTrolleyHovered = val;
    },
    showOverlay(val: boolean) {
      this.overlayForSearch = val;
    },
    pageshowCallback(event: PageTransitionEvent): void {
      if (event.persisted) {
        this.isTrolleyHovered = false;
      }
    },
  },
});
export default bottomNavbar;
