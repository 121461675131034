export default ({ app, store }) => {
  const { exponeaEnabled, exponeaNumberOfTries, exponeaTimeout } = app.$config;
  if (!exponeaEnabled) {
    return;
  }

  let numberOfTries = 0;

  const checkConsent = (group) => {
    const oneTrustLoadedEvent =
      window.dataLayer.find((item) => item.event === "cookieConsent") ||
      window.dataLayer.find((item) => item.event === "OneTrustLoaded");

    if (!oneTrustLoadedEvent) {
      return false;
    }

    const activeGroups =
      oneTrustLoadedEvent?.OnetrustActiveGroups?.split(",") ?? [];
    const cookieValues = oneTrustLoadedEvent?.cookievalues ?? {};

    return cookieValues[group] === "1" || activeGroups.includes(group);
  };

  const waitForConsent = () => {
    numberOfTries++;

    if (numberOfTries > exponeaNumberOfTries) return;

    if (checkConsent("C0002")) {
      exponea.start();
    } else {
      // console.log("Exponea - no consent data");
      setTimeout(waitForConsent, exponeaTimeout);
    }
  };

  window.addEventListener("myCustomConsentChangedEvent", function () {
    numberOfTries = 0;
    waitForConsent();
  });

  app.router.afterEach(() => {
    if (!exponea?.isInitialized) {
      waitForConsent();
    }

    if (!exponea?.configuration?.customer?.u_id) {
      const u_id = store.getters["auth/getUserId"];

      if (u_id) {
        exponea.identify({ u_id });
      }
    }
  });
};
