import { SiteIdWithStockInformation } from "./products";
import { ApiDeliveryMethod } from "~/services/api/trolleys.api.d";
import { ProductChannel } from "~/services/products.service";
import { Iso8601DateTime } from "~/types/iso-8601";
import { Product } from "~/types/product";

export interface TotalsItem {
  formatted: string;
  raw: number;
}

export interface TrolleyTotals {
  ex_vat: TotalsItem;
  inc_vat: TotalsItem;
  vat: TotalsItem;
}

export interface LineItem {
  id: number;
  channel: number;
  created_at: Iso8601DateTime;
  delivery_method_code: string;
  product_code: string;
  product_channel: number;
  quantity: number;
  product: Product;
  updated_at: Iso8601DateTime;
}

export interface LineItemWithStock extends LineItem {
  stock: SiteIdWithStockInformation;
}

export interface StoreStock {
  message: string;
  lines: Array<{
    code: string;
    name: string;
  }>;
}

export interface QualifyingPromotionsData {
  id: number;
  code: string;
  name: string;
}

export interface LineDiscountsData {
  grant_id: number;
  product_code: string;
  promotion_id: number;
  amount: number;
  staff_discount: boolean;
  type: string;
}

export interface FreeProductsData {
  product_code: string;
  quantity: number;
}

export interface AppliedPromotionsData {
  id: number;
  code: string;
  applied_at: Iso8601DateTime;
}

export interface DiscountData {
  expires: string;
  discount_total: string;
  line_discounts: LineDiscountsData[];
  qualifying_promotions: QualifyingPromotionsData[];
  free_products: FreeProductsData[];
  applied_promo_codes: AppliedPromotionsData[];
  messages: string[];
}

export interface TrolleyData {
  id: string;
  customer_id: string | null;
  session_id: string;
  status: number;
  name: string | null;
  created_at: string;
  updated_at: string;
  attributes: any[];
  session_token: string;
  allLinesIsDelivery?: boolean;
  delivery?: TrolleyTotals;
  discount?: string;
  items?: TrolleyTotals;
  lines?: LineItem[];
  quantityLimitedLines?: any[];
  stock?: StoreStock[];
  subtotal?: string;
  total?: string;
  totalExcVat?: string;
  totalVat?: string;
  vouchers?: string[];
  subtotals?: TrolleyTotals;
  totals?: TrolleyTotals;
  trolley_discount: DiscountData | null;
}

export interface TrolleyState {
  trolleyId: string | null;
  trolley: Partial<TrolleyData> | null;
  addedToModalVisible: boolean;
  lastTrolleyUpdateProductCodes: string[] | [];
  lastTrolleyUpdateChannel: ProductChannel | null;
  loading: boolean;
  trolleyUpdateError: boolean;
  deliveryAddressId: string;
  collectionAddressId: string;
  billingAddressId: string;
  deliveryMethods: ApiDeliveryMethod[];
  deliveryMethod: { [key: string]: string };
  deliveryDetails: string;
  poNumber: string;
  staffDiscountToken: string | null;
}

export type RawTrolleyData = TrolleyData & { lines: LineItem[] };

export enum TrolleyStatus {
  Active = 1,
}

export enum TrolleyPromotionIDs {
  TS_CLUB_PROMO = 2718,
}
