import { isNil, map, mapObjIndexed } from "ramda";
import {
  BrDocumentResponse,
  DocumentPathKey,
  getBrDocument,
  getBrExperiencePage,
} from "~/services/api/bloomreach.api";
import { handleError } from "~/services/helpers";
import { get } from "~/utils/lru-cache";
import { camelizeKeys } from "~/utils/utils";

/**
 * From a BR document, fetch related content via it's $ref
 */
const resolveBrReferences = (content: any) => {
  const resolveBrReferences = (item: any): any => {
    if (isNil(item)) return item;
    if (Array.isArray(item)) return listResolveBrReferences(item);
    if (typeof item === "string") return item;
    if (typeof item === "object" && "$ref" in item)
      return content?.[item.$ref.replace("/content/", "")]?.data;
    if (typeof item === "object") return objResolveBrReferences(item);

    return item;
  };

  const objResolveBrReferences = mapObjIndexed(resolveBrReferences);
  const listResolveBrReferences = map(resolveBrReferences);

  return resolveBrReferences;
};

/**
 * Fetch Bloomreach document data
 * @param documentPath
 * @param document
 * @param brContentURL
 */
export const fetchBrDocument = async (
  documentPath: DocumentPathKey,
  document: string,
  brContentURL: string,
  previewToken?: string
): Promise<any> => {
  try {
    const fetchDocument = async (): Promise<any> => {
      return await getBrDocument(
        documentPath,
        document,
        brContentURL,
        previewToken
      )
        .then((response: BrDocumentResponse) => {
          const documentUid = response?.document.$ref.split("/").slice(-1)[0];
          const documentData = response?.content[documentUid].data;

          const resolve = resolveBrReferences(response?.content);

          return resolve(documentData);
        })
        .then(camelizeKeys);
    };

    if (previewToken !== undefined) {
      return fetchDocument();
    }

    return await get(
      `bloomreachDocument.${documentPath}.${document}`,
      fetchDocument
    );
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch Bloomreach experience page
 * @param config
 * @param getCachedPage
 */
export const fetchBrExperiencePage = async (
  config: any,
  getCachedPage: boolean
): Promise<any> => {
  try {
    const { url } = config;
    return getCachedPage
      ? await get(url, async () => await getBrExperiencePage(config))
      : await getBrExperiencePage(config);
  } catch (error) {
    handleError(error);
  }
};
