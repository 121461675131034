import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";
import { cloneObject } from "~/utils/utils";

export enum FeatureFlagsMutation {
  SetFlags = "setFlags",
  ServerOmniTrackingData = "serverOmniTrackingData",
}

interface FeatureFlagsState {
  serverOmniTrackingData?: object;
  showProductPageVersion2?: boolean;
  vatRequired?: boolean;
  showEUAddressForm: boolean;
  siteMessagesEnabled?: boolean;
  showEmailTrolleyButton?: boolean;
  suggestPlatform?: string;
  recommendationWidgets?: object;
}

export const state = (): {} => ({});

export const mutations: MutationTree<FeatureFlagsState> = {
  [FeatureFlagsMutation.SetFlags]: (state: FeatureFlagsState, flags: any) => {
    Object.keys(flags).forEach((key) => {
      // @ts-ignore
      state[key] = flags[key];
    });
  },
  [FeatureFlagsMutation.ServerOmniTrackingData]: (
    state: FeatureFlagsState,
    val: object
  ) => {
    state.serverOmniTrackingData = val;
  },
};

export const actions: ActionTree<FeatureFlagsState, RootState> = {
  async parseFeatureFlags({ commit, state }) {
    const hardCodedFeatureFlags = await getFeatureFlags();
    const featureFlagState = cloneObject(hardCodedFeatureFlags);

    // if feature flag has already been set from monetate flags - don't overwrite it
    Object.keys(featureFlagState).forEach((featureFlag: string) => {
      // @ts-ignore
      if (state[featureFlag]) {
        delete featureFlagState[featureFlag];
      }
    });

    commit(FeatureFlagsMutation.SetFlags, featureFlagState);
  },
};

export const getters: GetterTree<FeatureFlagsState, RootState> = {
  getFeatureFlags: (state) => state,
  siteMessagesEnabled: (state) => state.siteMessagesEnabled,
  serverOmniTrackingData: (state) => state.serverOmniTrackingData,
};

export default {
  state,
  getters,
  mutations,
  actions,
};

const getFeatureFlags = async () => {
  const localInstance = process.env.NUXT_APP_LOCALE_INSTANCE;
  try {
    const constants = await import(`~/constants/${localInstance}.json`);
    return constants.featureFlags;
  } catch (e) {
    return {};
  }
};
