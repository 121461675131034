<template>
  <div>
    <BaseCarousel
      :key="recommendationData.data.length"
      :settings="carouselSetting"
      :class="{ 'version-2': isVersion('two') }"
    >
      <template #slides>
        <div
          v-for="(recommendation, index) in recommendationData.data"
          :key="index"
        >
          <LoadingCard v-if="isLoading" />
          <component
            :is="cardType"
            v-else
            :image-url="recommendation.thumbImage"
            :title="recommendation.title"
            :reviews="recommendation.tsReviews || 0"
            :gross-price="getPrice(recommendation, 'gross')"
            :net-price="getPrice(recommendation, 'net')"
            :saving-value="getSavingValue(recommendation)"
            :product="recommendation"
            :show-add-to-trolley="showAddToTrolley"
            :cta="cta"
            :show-flash="showFlash"
            :web-overlay-text="recommendation.weboverlaytext"
            :badge-icons="
              showBadges
                ? {
                    brand: {
                      imageUrl: getBadgeUrl(
                        recommendation.brand || '',
                        'brand'
                      ),
                      altText: recommendation.brand || '',
                    },
                    badge: {
                      imageUrl: getBadgeUrl(
                        recommendation.assettr || '',
                        'badge'
                      ),
                      altText: recommendation.brand || '',
                    },
                  }
                : {}
            "
            @trackClickPixel="trackClick"
          />
        </div>
      </template>
      <template #backArrow>
        <!--Back btn for version1-->
        <ChevronIcon
          v-if="isVersion('one')"
          classes="w-10 text-black-100"
          direction="left"
        />
        <!--Back btn for version2-->
        <span v-else class="navigation-btn-wrapper">
          <img
            src="~/assets/svg/up-arrow.svg"
            alt="right-arrow"
            class="-rotate-90 -ml-1"
          />
        </span>
      </template>
      <template #nextArrow>
        <!--Next btn for version1-->
        <ChevronIcon
          v-if="isVersion('one')"
          classes="w-10 text-black-100"
          direction="right"
        />
        <!--Next btn for version2-->
        <span
          v-if="isVersion('two') && showNextBtn"
          class="navigation-btn-wrapper"
        >
          <img
            src="~/assets/svg/up-arrow.svg"
            alt="right-arrow"
            class="rotate-90 -mr-1"
          />
        </span>
      </template>
    </BaseCarousel>
  </div>
</template>
<script>
import PathwaysAndRecsMixin from "../mixins/PathwaysAndRecsMixin";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import { ChevronIcon } from "~/components/UI/icons";

export default {
  name: "PathwaysAndRecsCarousel",
  components: {
    BaseCarousel,
    ChevronIcon,
    CardVersionOne: () =>
      import(
        /* webpackChunkName: "CardVersionOne" */ "~/components/product-recommendations/cards/VersionOne.vue"
      ),
    CardVersionTwo: () =>
      import(
        /* webpackChunkName: "CardVersionTwo" */ "~/components/product-recommendations/cards/VersionTwo.vue"
      ),
    LoadingCard: () =>
      import(
        /* webpackChunkName: "LoadingCard" */ "~/components/product-recommendations/cards/LoadingCard.vue"
      ),
  },
  mixins: [PathwaysAndRecsMixin],
  props: {
    cta: {
      type: String,
      required: false,
      default: "",
    },
    showBadges: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFlash: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddToTrolley: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoPlayDelay: {
      type: Number,
      required: false,
      default: 6,
    },
    cardType: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    slidesPerPage: {
      type: Object,
      required: false,
      default: () => {
        return {
          desktop: 4,
          tablet: 3,
          mobile: 1,
        };
      },
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      recommendationData: {
        data: [
          {}, // Placeholder object, required for initial render
          {},
          {},
          {},
          {},
          {},
        ],
      },
    };
  },
  fetch() {
    if (!this.isLoading) {
      this.recommendationData = this.data;
    }
  },
  computed: {
    carouselSetting() {
      return {
        showDots: this.isVersion("two"),
        autoplayDelay: 6,
        slidesPerPage: this.slidesPerPage.desktop,
        paginateBySlide: false,
        showArrows: true,
        gutter: "3rem",
        loop: this.slidesPerPage.desktop !== this.data.data?.length,
        responsive: [
          {
            maxWidth: 1024,
            slidesPerPage: this.slidesPerPage.tablet,
            loop: this.slidesPerPage.tablet !== this.data.data?.length,
          },
          {
            maxWidth: 640,
            slidesPerPage: this.slidesPerPage.mobile,
            gutter: "1.5rem",
            loop: this.slidesPerPage.mobile !== this.data.data?.length,
          },
        ],
      };
    },
    showNextBtn() {
      return this.data.data?.length > this.slidesPerPage.desktop ?? true;
    },
  },
  watch: {
    data: function (newVal, _oldVal) {
      this.recommendationData = newVal;
    },
  },
  methods: {
    isVersion(versionCheck) {
      switch (versionCheck) {
        case "one":
          return this.cardType === "CardVersionOne";
        case "two":
          return this.cardType === "CardVersionTwo";
        default:
          return false;
      }
    },
    trackClick(data) {
      this.$emit("trackClickPixel", data);
    },
  },
};
</script>

<style lang="postcss" scoped>
:deep(.ssr-carousel-slide) {
  @apply py-2;
}

/*** Version 2  ***/
.version-2 {
  :deep(.ssr-carousel-arrows) {
    @apply opacity-0;
  }
  .navigation-btn-wrapper {
    @apply bg-blue h-11 w-11 hidden md:flex justify-center items-center rounded-full border-2 border-white drop-shadow-lg;

    &:hover {
      @apply opacity-100;
    }
  }
  :deep(.ssr-carousel-next-button) {
    @apply right-[-16px];
  }
  :deep(.ssr-carousel-back-button) {
    @apply left-[-16px];
  }

  :deep(.ssr-carousel-dots) {
    @apply mt-0 mb-2;
  }

  :deep([disabled] > .ssr-carousel-dot-icon) {
    @apply bg-blue scale-125;
  }

  :deep([aria-disabled="true"] > .ssr-carousel-dot-icon) {
    @apply bg-blue scale-125;
  }

  :deep(.ssr-carousel-dot-icon) {
    @apply bg-grey-4 border-none w-[8px] h-[8px] opacity-70 ease-in-out duration-200;
  }
}
</style>
