
import Vue from "vue";
import { NuxtI18nMeta } from "@nuxtjs/i18n/types/vue";
import { PortalTarget } from "portal-vue";
import { mapGetters, mapState } from "vuex";
import { MetaInfo } from "vue-meta";
import AppBanners from "~/components/UI/banners/app-banners/AppBanners.vue";
import TrackingSubscriber from "~/mixins/TrackingSubscriber";
import SaveForLaterModal from "~/components/product-listing/body/products/modals/SaveForLaterModal.vue";
import LoginModal from "~/components/UI/modals/auth/LoginModal.vue";
import AddedToTrolleyModal from "~/components/UI/modals/trolley-modal/AddedToTrolleyModal.vue";
import SiteMessages from "~/components/UI/alerts/site-messages/SiteMessages.vue";
import { fromToolstationCdnUrl } from "~/utils/fromToolstationCdn";

export default Vue.extend({
  name: "BaseLayout",
  components: {
    PortalTarget,
    AppBanners,
    AddedToTrolleyModal,
    LoginModal,
    SiteMessages,
    SiteFooter: () =>
      import(
        /* webpackChunkName: "TheFooter" */ "~/components/layout/footer/TheFooter.vue"
      ),
    UspBanner: () =>
      import(
        /* webpackChunkName: "UspBanner" */ "~/components/layout/usp-banner/UspBanner.vue"
      ),
    NextBusinessDayModal: () =>
      import(
        /* webpackChunkName: "NextBusinessDayModal" */ "~/components/UI/modals/nextbusinessday-modal/NextBusinessDayModal.vue"
      ),
    SaveForLaterModal,
  },
  mixins: [TrackingSubscriber],
  beforeRouteLeave() {
    this.$store.dispatch("stock/teardown");
  },
  layout: "default",
  props: {
    reduced: {
      type: Boolean,
      default: false,
    },
    hideUspBanner: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    bottomNavbarFixed: false,
    bottomNavbarHeight: 0,
    bottomNavbarId: "bottomNavbar",
  }),
  head(): MetaInfo {
    // Helpers provided by @nuxtjs/i18n https://i18n.nuxtjs.org/seo/
    const i18nHead: NuxtI18nMeta = this.$nuxtI18nHead({
      addSeoAttributes: true,
    });

    const domain = this.$store.getters.domain;

    return {
      title: "Toolstation",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { hid: "description", name: "description", content: "" },
        { name: "format-detection", content: "telephone=no" },
        {
          name: "theme-color",
          content: "#004F9F",
        },
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: "Toolstation",
        },
        { hid: "og:title", property: "og:title", content: "Toolstation" },
        { hid: "og:description", property: "og:descripion", content: "" },
        { hid: "og:type", property: "og:type", content: "Website" },
        {
          hid: "og:url",
          property: "og:url",
          content: `${domain}`,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: fromToolstationCdnUrl(
            "img/toolstation-halo.svg",
            this.$config
          ),
        },
        ...(this.$config.appVersion
          ? [
              {
                hid: "version",
                name: "version",
                content: this.$config.appVersion,
              },
            ]
          : []),
        ...i18nHead.meta,
      ],
      link: [
        {
          rel: "icon",
          href: "/icon/favicon-32-x-32.png",
          sizes: "32x32",
        },
        {
          rel: "icon",
          href: "/icon/favicon-128-x-128.png",
          sizes: "128x128",
        },
        {
          rel: "icon",
          href: "/icon/favicon-192-x-192.png",
          sizes: "192x192",
        },
        {
          rel: "shortcut icon",
          href: "/icon/favicon-196-x-196.png",
          sizes: "196x196",
        },
        {
          rel: "apple-touch-icon",
          href: "/icon/favicon-180-x-180.png",
          sizes: "180x180",
        },
        { rel: "preconnect", href: "https://www.googletagmanager.com" },
        { rel: "preconnect", href: "https://googleads.g.doubleclick.net" },
        { rel: "preconnect", href: "https://ad.doubleclick.net" },
        { rel: "preconnect", href: "https://adservice.google.com" },
        { rel: "preconnect", href: "https://f.monetate.net" },
        { rel: "preconnect", href: "https://recommender.scarabresearch.com" },
        { rel: "preconnect", href: "https://se.monetate.net" },
        ...i18nHead.link,
      ],
      htmlAttrs: {
        class: "text-html-root bg-grey-2",
        ...i18nHead.htmlAttrs,
      },
      __dangerouslyDisableSanitizers: ["script", "innerHTML"],
      script: [
        ...(Boolean(this.$config.webloyaltyEnabled) === true
          ? [
              {
                innerHTML: `var prodID = { "a": [], "c": 0, "u": "", "r": false }; function _lppr(v){for(var i=0;i<prodID.a.length;i++){if(prodID.a[i].h===v){return i;}}}`,
              },
            ]
          : []),
        {
          src: "https://sdk.woosmap.com/localities/localities.2.0.js",
          async: true,
          rel: "preload",
        },
        ...(!this.$route.query["server-id"]
          ? [
              ...(Boolean(this.$config.oneTrustAutoblockEnabled) === true
                ? [
                    {
                      src: `https://cdn-ukwest.onetrust.com/consent/${
                        this.$config.oneTrustDataDomainScript
                      }${
                        this.$config.deploymentEnvironment !== "prod"
                          ? "-test"
                          : ""
                      }/OtAutoBlock.js`,
                    },
                  ]
                : []),
              {
                src: "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
                async: true,
                rel: "preload",
                "data-domain-script": `${
                  this.$config.oneTrustDataDomainScript
                }${
                  this.$config.deploymentEnvironment !== "prod" ? "-test" : ""
                }`,
              },
            ]
          : []),

        {
          src: `/external/js/oneTrustConsent.js?v=${this.$config.appVersion}`,
          async: true,
          rel: "preload",
        },
        {
          src: "/external/js/exponea.js",
          async: true,
          rel: "preload",
          "data-token": this.$config.exponeaToken,
        },
        {
          src: "https://sdk.woosmap.com/localities/localities.2.0.js",
          async: true,
          rel: "preload",
        },
        {
          src: "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
          async: true,
          rel: "preload",
        },
        ...(Boolean(this.$config.monetate.enabled) === true
          ? [
              {
                type: "text/javascript",
                innertHTML: `var monetateT = new Date().getTime();`,
                json: {},
              },
              {
                src: `//se.monetate.net/js/2/${this.$config.monetate.id}/${
                  this.$config.monetate.account.endsWith(".com") ? "p" : "d"
                }/${this.$config.monetate.account}/entry.js`,
                rel: "preload",
              },
            ]
          : []),
      ],
    };
  },
  computed: {
    ...mapState("search", ["nextBusinessDayModalVisible"]),
    ...mapGetters("locale", ["getSsrPageUrl"]),
    ...mapGetters("trolley", ["isAddedToTrolleyModalVisible"]),
    ...mapGetters("auth", ["loginModalVisible"]),
    ...mapGetters("siteMessages", ["getMessages", "messagesSet"]),
    ...mapGetters("featureFlags", ["getFeatureFlags", "siteMessagesEnabled"]),

    pageUrl(): string {
      return `https://${
        process.server
          ? (this.getSsrPageUrl as string) || ""
          : window.location.host
      }`;
    },
    bottomNavbarFixedStyle(): string {
      return this.bottomNavbarFixed
        ? `padding-top:${this.bottomNavbarHeight}px`
        : "";
    },
  },
  methods: {
    onBottomNavbarFixed(isFixed: boolean): void {
      const bottomNavEl = document.getElementById(this.bottomNavbarId);
      if (bottomNavEl) {
        this.bottomNavbarFixed = !isFixed;
        this.bottomNavbarHeight = bottomNavEl.clientHeight;
      }
    },
  },
});
