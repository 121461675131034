export default {
  // Need to add priceRange and review to this array when it comes to creating these filters
  facetGrammarPresetOrder: [
    { label: "energyclass", type: "Facet Name" },
    { label: "maxpressure", type: "Facet Name" },
    { label: "headtype", type: "Facet Name" },
    { label: "gender", type: "Facet Name" },
    { label: "clothingsize", type: "Facet Name" },
    { label: "lengthbackplate", type: "Facet Name" },
    { label: "kw", type: "Facet Name" },
    { label: "torque", type: "Facet Name" },
    { label: "assettr", type: "Facet Name" },
    { label: "lumens", type: "Facet Name" },
    { label: "screwhead", type: "Facet Name" },
    { label: "valve", type: "Facet Name" },
    { label: "brand", type: "Facet Name" },
    { label: "diameter_mm", type: "Facet Name" },
    { label: "fittingtype", type: "Facet Name" },
    { label: "safetyclass", type: "Facet Name" },
    { label: "toecaptype", type: "Facet Name" },
    { label: "maxsplittingforce", type: "Facet Name" },
    { label: "tipsize", type: "Facet Name" },
    { label: "containertype", type: "Facet Name" },
    { label: "resolution", type: "Facet Name" },
    { label: "animaltype", type: "Facet Name" },
    { label: "gangs", type: "Facet Name" },
    { label: "leglength", type: "Facet Name" },
    { label: "shape", type: "Facet Name" },
    { label: "ways", type: "Facet Name" },
    { label: "volume", type: "Facet Name" },
    { label: "dimmable", type: "Facet Name" },
    { label: "diameter", type: "Facet Name" },
    { label: "iprating", type: "Facet Name" },
    { label: "length", type: "Facet Name" },
    { label: "lengthmeters", type: "Facet Name" },
    { label: "size", type: "Facet Name" },
    { label: "sizemm", type: "Facet Name" },
    { label: "sizeinch", type: "Facet Name" },
    { label: "amprating", type: "Facet Name" },
    { label: "batterysize", type: "Facet Name" },
    { label: "width", type: "Facet Name" },
    { label: "widthfeet", type: "Facet Name" },
    { label: "openingwidth", type: "Facet Name" },
    { label: "thread", type: "Facet Name" },
    { label: "profile", type: "Facet Name" },
    { label: "boresize", type: "Facet Name" },
    { label: "height", type: "Facet Name" },
    { label: "heightfeet", type: "Facet Name" },
    { label: "lengthhandle", type: "Facet Name" },
    { label: "unittype", type: "Facet Name" },
    { label: "finish", type: "Facet Name" },
    { label: "pipecentres", type: "Facet Name" },
    { label: "fitteddepth", type: "Facet Name" },
    { label: "precut", type: "Facet Name" },
    { label: "volumeml", type: "Facet Name" },
    { label: "colour", type: "Facet Name" },
    { label: "shoesize", type: "Facet Name" },
    { label: "waterresistance", type: "Facet Name" },
    { label: "voltage", type: "Facet Name" },
    { label: "brushless", type: "Facet Name" },
    { label: "wattage", type: "Facet Name" },
    { label: "depth", type: "Facet Name" },
    { label: "depthfeet", type: "Facet Name" },
    { label: "batterytype", type: "Facet Name" },
    { label: "fitting", type: "Facet Name" },
    { label: "cordless", type: "Facet Name" },
    { label: "lightbulbcolour", type: "Facet Name" },
    { label: "colourtemp", type: "Facet Name" },
    { label: "battery", type: "Facet Name" },
    { label: "chucktype", type: "Facet Name" },
    { label: "energysaving", type: "Facet Name" },
    { label: "alarmtype", type: "Facet Name" },
    { label: "material", type: "Facet Name" },
    { label: "lighttechnology", type: "Facet Name" },
    { label: "cabletype", type: "Facet Name" },
    { label: "batteriesincluded", type: "Facet Name" },
    { label: "chiselfitting", type: "Facet Name" },
    { label: "cablecode", type: "Facet Name" },
    { label: "grit", type: "Facet Name" },
    { label: "treads", type: "Facet Name" },
    { label: "rating", type: "Facet Name" },
    { label: "wras", type: "Facet Name" },
    { label: "btu", type: "Facet Name" },
    { label: "teeth", type: "Facet Name" },
    { label: "conductorsize", type: "Facet Name" },
    { label: "numberofcores", type: "Facet Name" },
    { label: "shanksize", type: "Facet Name" },
    { label: "review", type: "Facet Name" },
    { label: "minpressure", type: "Facet Name" },
    { label: "maxweight", type: "Facet Name" },
    { label: "maxheight", type: "Facet Name" },
    { label: "valveposition", type: "Facet Name" },
    { label: "type", type: "Facet Name" },
    { label: "weight", type: "Facet Name" },
    { label: "clothingsize_eu", type: "Facet Name" },
    { label: "technology", type: "Facet Name" },
    { label: "lampcaptype", type: "Facet Name" },
    { label: "substrate", type: "Facet Name" },
    { label: "drillbitdiameter", type: "Facet Name" },
    { label: "spannertype", type: "Facet Name" },
    { label: "pumptype", type: "Facet Name" },
    { label: "impellertype", type: "Facet Name" },
    { label: "showertrayshape", type: "Facet Name" },
    { label: "bturange", type: "Facet Name" },
    { label: "toiletseatclosetype", type: "Facet Name" },
    { label: "toiletseatfixingtype", type: "Facet Name" },
    { label: "numberofbowls", type: "Facet Name" },
    { label: "numberoftapholes", type: "Facet Name" },
    { label: "agegroup", type: "Facet Name" },
    { label: "waistsize", type: "Facet Name" },
    { label: "pocketstyle", type: "Facet Name" },
    { label: "reinforcedknees", type: "Facet Name" },
    { label: "adjustableorfixed", type: "Facet Name" },
    { label: "bezelcolour", type: "Facet Name" },
    { label: "lumensrange", type: "Facet Name" },
    { label: "volume", type: "Facet Name" },
    { label: "liquidbase", type: "Facet Name" },
    { label: "applicationmethod", type: "Facet Name" },
    { label: "title", type: "Taxonomy Name" },
  ],
  serverSideFilters: [
    "brand",
    "colour",
    "voltage",
    "type",
    "finish",
    "profile",
    "cordless",
    "material",
    "lumens",
    "shape",
    "lampcaptype",
    "brushless",
    "wattage",
    "batterytype",
    "teeth",
    "batteriesincluded",
    "battery",
    "shoesize",
    "category",
  ],
};
