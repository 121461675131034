import { isEmpty, isNil } from "ramda";

type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : never;

let cache: InstanceOf<typeof import("lru-cache")>;

async function initCache(duration: number): Promise<void> {
  if (process.server && cache === undefined) {
    const LRUCache = await import("lru-cache").then((r) => r.default);
    cache = new LRUCache({ max: 1000, ttl: 1000 * 60 * duration });
  }
}

// https://www.npmjs.com/package/lru-cache?activeTab=readme

export const get = async <T extends () => any>(
  key: string,
  callback: T,
  duration: number = 60 // how long to live in minutes, defaults to 1 hour / 60 minutes
): Promise<ReturnType<T>> => {
  await initCache(duration);
  if (cache === undefined) {
    return callback();
  }

  if (!cache.has(key)) {
    // console.log("Cache does not have the key:", key);
    const value = await callback();

    // Dont cache the value if the callback returns null
    if (isNil(value) || isEmpty(value)) {
      return value;
    }

    cache.set(key, value);
    // console.log("Cache value has been set for key", key);
    return value;
  }

  // const remainingTTL = Math.round(cache.getRemainingTTL(key) / 1000);
  // console.log("Cache does have the key:", key);
  // console.log(`Cache Remaining TTL: ${remainingTTL}s for:`, key);
  // console.log('cache contents: ', cache.get(key));
  return cache.get(key) as ReturnType<T>;
};
