<template>
  <div
    v-if="data[0] && (data[0].imageUrl || data[0].backgroundColour)"
    class="flex flex-col items-center"
  >
    <div
      class="flex flex-wrap justify-between w-full gap-x-3 gap-y-6 md:gap-7"
      :class="alignLeft ? 'md:justify-start' : 'md:justify-center'"
    >
      <!--  When in Carousel mode  -->
      <BaseCarousel v-if="isCarousel" :settings="carouselSetting">
        <template #slides>
          <Component
            :is="getElementType(item.link.externalLink)"
            v-bind="getHref(item.link.externalLink)"
            v-for="(item, index) in data"
            :key="index"
            :class="widthClasses"
          >
            <Component
              :is="getVariant()"
              :height-classes="heightClasses"
              :image-url="item.imageUrl"
              :background-colour="item.backgroundColour"
              :title="item.title"
              :cta="{
                path: item.link.externalLink,
                linkText: item.link.linkText,
                colour: ctaBtnColour,
              }"
              :title-text-colour="titleTextColour"
            ></Component>
          </Component>
        </template>
      </BaseCarousel>

      <!--  When NOT in Carousel mode -->
      <template v-else>
        <Component
          :is="getElementType(item.link.externalLink)"
          v-bind="getHref(item.link.externalLink)"
          v-for="(item, index) in computedData"
          :key="index"
          :class="widthClasses"
          data-testid="item-wrapper"
        >
          <Component
            :is="getVariant()"
            :height-classes="heightClasses"
            :image-url="item.imageUrl"
            :background-colour="item.backgroundColour"
            :title="item.title"
            :image-classes="imageClasses"
            :cta="{
              path: item.link.externalLink,
              linkText: item.link.linkText,
              colour: ctaBtnColour,
            }"
            :title-text-colour="titleTextColour"
          ></Component>
        </Component>
      </template>
    </div>
    <DefaultButton
      v-if="showViewMoreButton && !isCarousel && splitResults()"
      v-show="!isDesktop && initialUpdate"
      ref="button"
      class="md:hidden mt-6 !px-24 !py-3"
      @clickAction="toggleExpand()"
    >
      {{ computedButtonText }}
    </DefaultButton>
  </div>
</template>

<script setup>
import { computed, ref, nextTick } from "vue";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { store } from "~/composables/utils";

// Variants
import VersionOne from "~/components/multi-text-and-image/variants/VersionOne.vue";
import VersionTwo from "~/components/multi-text-and-image/variants/VersionTwo";
import VersionThree from "~/components/multi-text-and-image/variants/VersionThree";
import VersionFour from "~/components/multi-text-and-image/variants/VersionFour";
import VersionFive from "~/components/multi-text-and-image/variants/VersionFive";
import VersionSix from "~/components/multi-text-and-image/variants/VersionSix";
import VersionSeven from "~/components/multi-text-and-image/variants/VersionSeven";
import VersionEight from "~/components/multi-text-and-image/variants/VersionEight";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  styleType: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    required: true,
  },
  isCarousel: {
    type: Boolean,
    required: false,
    default: false,
  },
  itemsPerRowDesktop: {
    type: Number,
    required: true,
  },
  itemsPerRowTablet: {
    type: Number,
    required: true,
  },
  itemsPerRowMobile: {
    type: Number,
    required: true,
  },
  collapsedItemCount: {
    type: Object,
    required: false,
    default: () => {},
  },
  showViewMoreButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  buttonText: {
    type: Object,
    required: false,
    default: () => {},
  },
  alignLeft: {
    type: Boolean,
    required: false,
    default: false,
  },
  imageClasses: {
    type: String,
    required: false,
    default: "",
  },
  ctaBtnColour: {
    type: String,
    required: false,
    default: "",
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "",
  },
});

function getElementType(val) {
  return val ? "a" : "div";
}

function getHref(val) {
  return val ? { href: val } : {};
}

const initialUpdate = computed(() => store().getters["viewport/initialUpdate"]);
const isMobile = computed(() => store().getters["viewport/isMobile"]);
const isTablet = computed(() => store().getters["viewport/isTablet"]);
const isDesktop = computed(() => store().getters["viewport/isDesktop"]);

const computedData = computed(() => {
  if (!splitResults()) return props.data;

  const dataLength = isTablet.value
    ? props.collapsedItemCount.tablet
    : props.collapsedItemCount.mobile;

  return isExpanded.value ? props.data : props.data.slice(0, dataLength) || [];
});

function splitResults() {
  const { showViewMoreButton, collapsedItemCount, data } = props;

  if (!showViewMoreButton || isDesktop.value) return false;
  if (isTablet.value && collapsedItemCount.tablet >= data.length) return false;
  if (isMobile.value && collapsedItemCount.mobile >= data.length) return false;

  return true;
}

const computedButtonText = computed(() => {
  return isExpanded.value
    ? props.buttonText.expanded
    : props.buttonText.collapsed;
});

const isExpanded = ref(false);
const button = ref(null);

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
  if (!isExpanded.value) {
    // Remove smooth scrolling on page
    document.documentElement.style.scrollBehavior = "unset";

    // Jump back to button when collapsed
    nextTick(() => {
      const element = button.value.$el;
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: y - 150 });
    });
  }
}

const carouselSetting = {
  autoplayDelay: 6,
  slidesPerPage: props.itemsPerRowDesktop,
  paginateBySlide: true,
  loop: props.itemsPerRowDesktop !== props.data.length,
  responsive: [
    {
      maxWidth: 1024,
      slidesPerPage: props.itemsPerRowTablet,
      loop: props.itemsPerRowDesktop !== props.data.length,
    },
    {
      maxWidth: 640,
      slidesPerPage: props.itemsPerRowMobile,
      loop: props.itemsPerRowDesktop !== props.data.length,
    },
  ],
};

const heightClasses = computed(() => {
  if (props.isCarousel) return "h-auto";

  switch (props.height) {
    case "extra small":
      return "h-[130px] md:h-[140px]";
    case "small":
      return "h-[200px] md:h-[250px]";
    case "medium":
      return "h-[250px] md:h-[300px]";
    case "large":
      return "h-[300px] md:h-[350px]";
    default:
      return "h-[250px] md:h-[300px]";
  }
});

const widthClasses = computed(() => {
  if (props.isCarousel) return "";

  const desktop = getWidthClasses(props.itemsPerRowDesktop, "desktop");
  const tablet = getWidthClasses(props.itemsPerRowTablet, "tablet");
  const mobile = getWidthClasses(props.itemsPerRowMobile, "mobile");

  function getWidthClasses(val, deviceType) {
    switch (val) {
      case 1:
        return deviceType === "desktop"
          ? `lg:w-full`
          : deviceType === "tablet"
            ? "md:w-full"
            : "w-full";
      case 2:
        return deviceType === "desktop"
          ? `lg:w-[calc(50%-28px)]`
          : deviceType === "tablet"
            ? "md:w-[calc(50%-28px)]"
            : "w-[calc(50%-12px)]";
      case 3:
        return deviceType === "desktop"
          ? `lg:w-[calc(33.3%-28px)]`
          : deviceType === "tablet"
            ? "md:w-[calc(33.3%-28px)]"
            : "w-[calc(33.3%-12px)]";
      case 4:
        return deviceType === "desktop"
          ? `lg:w-[calc(25%-28px)]`
          : deviceType === "tablet"
            ? "md:w-[calc(25%-28px)]"
            : "w-[calc(25%-12px)]";
      case 5:
        return deviceType === "desktop"
          ? `lg:w-[calc(20%-28px)]`
          : deviceType === "tablet"
            ? "md:w-[calc(20%-28px)]"
            : "w-[calc(20%-12px)]";
      case 6:
        return deviceType === "desktop"
          ? `lg:w-[calc(16.6%-28px)]`
          : deviceType === "tablet"
            ? "md:w-[calc(16.6%-28px)]"
            : "w-[calc(16.6%-12px)]";
      default:
        return `w-full`;
    }
  }

  return `${mobile} ${tablet} ${desktop}`;
});

function getVariant() {
  switch (props.styleType) {
    case "version 1":
      return VersionOne;
    case "version 2":
      return VersionTwo;
    case "version 3":
      return VersionThree;
    case "version 4":
      return VersionFour;
    case "version 5":
      return VersionFive;
    case "version 6":
      return VersionSix;
    case "version 7":
      return VersionSeven;
    case "version 8":
      return VersionEight;
    default:
      return VersionOne;
  }
}
</script>

<style lang="postcss" scoped></style>
