var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data[0] && (_vm.data[0].imageUrl || _vm.data[0].backgroundColour))?_c('div',{staticClass:"flex flex-col items-center"},[_c('div',{staticClass:"flex flex-wrap justify-between w-full gap-x-3 gap-y-6 md:gap-7",class:_vm.alignLeft ? 'md:justify-start' : 'md:justify-center'},[(_vm.isCarousel)?_c(_setup.BaseCarousel,{attrs:{"settings":_setup.carouselSetting},scopedSlots:_vm._u([{key:"slides",fn:function(){return _vm._l((_vm.data),function(item,index){return _c(_setup.getElementType(item.link.externalLink),_vm._b({key:index,tag:"Component",class:_setup.widthClasses},'Component',_setup.getHref(item.link.externalLink),false),[_c(_setup.getVariant(),{tag:"Component",attrs:{"height-classes":_setup.heightClasses,"image-url":item.imageUrl,"background-colour":item.backgroundColour,"title":item.title,"cta":{
              path: item.link.externalLink,
              linkText: item.link.linkText,
              colour: _vm.ctaBtnColour,
            },"title-text-colour":_vm.titleTextColour}})],1)})},proxy:true}],null,false,3772090097)}):_vm._l((_setup.computedData),function(item,index){return _c(_setup.getElementType(item.link.externalLink),_vm._b({key:index,tag:"Component",class:_setup.widthClasses,attrs:{"data-testid":"item-wrapper"}},'Component',_setup.getHref(item.link.externalLink),false),[_c(_setup.getVariant(),{tag:"Component",attrs:{"height-classes":_setup.heightClasses,"image-url":item.imageUrl,"background-colour":item.backgroundColour,"title":item.title,"image-classes":_vm.imageClasses,"cta":{
            path: item.link.externalLink,
            linkText: item.link.linkText,
            colour: _vm.ctaBtnColour,
          },"title-text-colour":_vm.titleTextColour}})],1)})],2),_vm._v(" "),(_vm.showViewMoreButton && !_vm.isCarousel && _setup.splitResults())?_c(_setup.DefaultButton,{directives:[{name:"show",rawName:"v-show",value:(!_setup.isDesktop && _setup.initialUpdate),expression:"!isDesktop && initialUpdate"}],ref:"button",staticClass:"md:hidden mt-6 !px-24 !py-3",on:{"clickAction":function($event){return _setup.toggleExpand()}}},[_vm._v("\n    "+_vm._s(_setup.computedButtonText)+"\n  ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }