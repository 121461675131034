import type { Context } from "@nuxt/types";
import type {
  LocaleObject,
  Options,
  RedirectOnOptions,
  Strategies,
} from "@nuxtjs/i18n";
import { isEmpty, isNil } from "ramda";
import type { CountryCode } from "~/types/iso-3166-1-alpha-2";
import type { LanguageCode } from "~/types/iso-639-1";

/*
  Language codes are lowercase by convention
  We could look to switch the key <=> value mapping here for better readability
*/
enum SupportedLanguage {
  nl = "Dutch",
  fr = "French",
  en = "English",
}
/* eslint-enable @typescript-eslint/naming-convention */

export enum SupportedCountry {
  GB = "United Kingdom",
  FR = "France",
  BE = "Belgium",
  NL = "Netherlands",
}

export type LocaleCode = `${LanguageCode}-${CountryCode}`;

const siteInternationalisationConfig: Partial<
  Record<CountryCode, LanguageCode[]>
> = {
  BE: ["nl"],
  FR: ["fr", "en"],
  GB: ["en"],
  NL: ["nl", "en"],
};

const locales: LocaleObject[] = [];
/* Ensure you have a file within ./utils/translations which corresponds to each ietfTag */
Object.entries(siteInternationalisationConfig).forEach(
  ([country, languages = []]) => {
    const countryKey = country as keyof typeof SupportedCountry;
    languages.forEach((language) => {
      const ietfTag = `${language}-${country}`;
      const languageKey = language as keyof typeof SupportedLanguage;
      locales.push({
        code: ietfTag,
        iso: ietfTag,
        file: `${ietfTag}.ts`,
        name: `${SupportedLanguage[languageKey]} in ${SupportedCountry[countryKey]}`,
      });
    });
  }
);

export const isCountrySupported = (countryCode: CountryCode): boolean =>
  Object.keys(SupportedCountry).includes(countryCode);

export const isLanguageSupported = (languageCode: LanguageCode): boolean =>
  Object.keys(SupportedLanguage).includes(languageCode);

const buildError = (message: string, code?: number, error?: unknown): Error =>
  Object.assign(new Error(message), {
    code: code ?? 500,
    error,
  });

const checkSupport = (
  check: (value: any) => boolean,
  values: string | string[],
  description: string
): void => {
  const valuesToTest = Array.isArray(values) ? values : [values];
  valuesToTest.forEach((value) => {
    if (value === "" || !check(value)) {
      throw buildError(
        `The value "${value}" is not permitted as ${description}. Please check your environment configuration.`,
        402
      );
    }
  });
};

const buildLocaleCode = (
  languageCode: LanguageCode,
  countryCode: CountryCode
): LocaleCode => `${languageCode}-${countryCode}` as const;

export const splitLocale = (
  localeCode: LocaleCode | string
): { languageCode: LanguageCode; countryCode: CountryCode } => {
  const [language, country] = localeCode.split("-");

  if (
    isNil(language) ||
    isEmpty(language) ||
    isNil(country) ||
    isEmpty(country)
  ) {
    throw buildError(`Unreadable locale "${localeCode}"`, 400);
  }

  return {
    languageCode: language as LanguageCode,
    countryCode: country as CountryCode,
  };
};

export const loadCountrySettings = async (
  country: string
): Promise<{ [key: string]: string }> => {
  try {
    return await import(`~/constants/${country}.json`);
  } catch (e) {
    throw buildError(`Error finding ${country} country data`, 500);
  }
};

export const changeLocale = async (
  context: Context,
  localeCode: LocaleCode
): Promise<void> => {
  try {
    const { countryCode, languageCode } = splitLocale(localeCode);
    checkSupport(isCountrySupported, countryCode, "a country");
    checkSupport(isLanguageSupported, languageCode, "a language");
  } catch (e) {
    context.$log.error(e);
    return;
  }
  await context.i18n.setLocale(localeCode);
};

export const getJsonFileContent = async (
  _: Context,
  lang: string
): Promise<{ [key: string]: string }> => {
  try {
    return await import(`~/utils/translations/data/${lang}.json`);
  } catch (e) {
    throw buildError(
      `Error finding ${lang}.json from ~/utils/translations/data`,
      406,
      e
    );
  }
};

export const getSupportedLocales = (country: CountryCode): LocaleObject[] => {
  const supportedLocales = (siteInternationalisationConfig[country] ?? []).map(
    (language: LanguageCode) => buildLocaleCode(language, country)
  );

  return locales.filter(({ code }) =>
    supportedLocales.includes(code as LocaleCode)
  );
};

/**
 * Displays the name of a language given its ISO code and the ISO code of the language in which the name is to be displayed.
 * @param displayType The type of the name to be displayed. It can be language, region, script or currency.
 * @param code The code to convert to text.
 * @param displayIn Code of the language in which the name is to be displayed. If omitted, the `code` parameter will be taken instead.
 */
export const getDisplayName = (
  displayType: "language" | "region" | "script" | "currency",
  code: LanguageCode | LocaleCode | CountryCode,
  displayIn?: LanguageCode
): string =>
  new Intl.DisplayNames(displayIn ?? code, {
    type: displayType,
  } as any).of(code) as string;

export const makei18nConfig = (
  instanceCountry: CountryCode,
  defaultLanguage: LanguageCode
): Partial<Options> => {
  const defaultLocale = buildLocaleCode(defaultLanguage, instanceCountry);

  const redirectOn: RedirectOnOptions = "root";
  const strategy: Strategies = "no_prefix";

  return {
    defaultLocale,
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: "toolstation-locale",
      redirectOn,
    },
    langDir: "utils/translations/",
    lazy: {
      skipNuxtState: true,
    },
    locales,
    skipSettingLocaleOnNavigate: true,
    strategy,
    vueI18n: {
      silentTranslationWarn: true,
      fallbackLocale: defaultLanguage === undefined ? "en-GB" : defaultLocale,
    },
    sortRoutes: false,
    parsePages: false,
  };
};
