import { Device } from "~/store/viewport";

export default ({ store }) => {
  const updateDevice = () => {
    const DESKTOP_WIDTH = 1025;
    const MOBILE_WIDTH = 640;
    const DEVICE_WIDTH = window.innerWidth;

    store.commit("viewport/setDeviceWidth", DEVICE_WIDTH);

    if (DEVICE_WIDTH >= DESKTOP_WIDTH) {
      store.commit("viewport/setDevice", Device.Desktop);
    } else if (DEVICE_WIDTH <= MOBILE_WIDTH) {
      store.commit("viewport/setDevice", Device.Mobile);
    } else {
      store.commit("viewport/setDevice", Device.Tablet);
    }
  };
  window.addEventListener("resize", updateDevice);

  // @todo it seems the resize event is fired after this update, so scheduling this to run on the
  // next tick sorts it. Figure out why or if there's a better way
  setTimeout(updateDevice);
};
