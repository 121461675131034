
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import { join, values } from "ramda";
import BaseLayout from "~/layouts/baseLayout.vue";
import SiteHeader from "~/components/layout/header/TheHeader.vue";
import AccountBreadcrumbs from "~/components/account/AccountBreadcrumbs.vue";
import TradeAccountMenu from "~/components/account/trade-account/TradeAccountMenu.vue";
import {
  AddAccountCustomerPayload,
  setSpendingLimits,
} from "~/services/account.service";
import { currencySymbol } from "~/utils/localeHelpers";
import { isErr, isOk, is422 } from "~/services/api";
import { UpdateSpendingLimitsPayload } from "~/services/api/tradeAccount.api.d";

export default Vue.extend({
  name: "TradeAccountLayout",
  components: {
    TradeAccountMenu,
    AccountBreadcrumbs,
    BaseLayout,
    SiteHeader,
  },
  layout: "TradeAccount",
  middleware: ["isLoggedIn", "isTradeCustomer"],
  data() {
    return {
      fetchingCardholders: false,
      updatingSpendingLimits: false,
      addingCardholder: false,
    };
  },
  async fetch() {
    this.fetchingCardholders = true;
    await this.$store.dispatch("auth/fetchAccounts");
    this.fetchingCardholders = false;
  },
  computed: {
    ...mapGetters("breadcrumbs", ["getBreadcrumbsItems"]),
    ...mapGetters("auth", [
      "getUser",
      "isAdminAccount",
      "getAccountId",
      "getAccount",
      "getAccounts",
      "getAccountCreditInformationNumericalised",
      "getCardHolders",
    ]),
    ...mapGetters("viewport", ["isDesktop", "isTabletOrMobile"]),

    scopedi18n(): Record<string, any> {
      return this.$t("account.tradeAccount") as object;
    },

    currencySymbol(): string {
      return currencySymbol(this.$i18n.locale, this.$config.currency);
    },
  },
  methods: {
    // @todo refactor baselayout so we can pass all event listeners to the top
    // At the moment, it's a slotted component so v-on="$listeners" won't work since the scope is bound to the component where it resides (which itself relies on slots)
    ...mapActions("auth", ["fetchCardholders"]),
    ...mapActions("breadcrumbs", ["setBreadcrumbs"]),

    deleteCardHolder(customerId: string) {
      return this.$store
        .dispatch("auth/deleteCardHolder", customerId)
        .then(() =>
          this.$toast.success(this.scopedi18n.cardHolderDeletedMessage)
        )
        .catch(() => {
          this.$toast.error(this.scopedi18n.cardHolderDeletedMessageFailure);
          this.fetchCardholders();
        });
    },

    addCardholder(data: AddAccountCustomerPayload) {
      this.addingCardholder = true;

      return this.$store
        .dispatch("auth/addCardHolder", data)
        .then(() => {
          this.$toast.success(this.scopedi18n.addCardHolderForm.successMessage);
          this.$router.push("/account/trade-account/manage-cardholders");
        })
        .catch((error) => {
          const message =
            error?.message ??
            error?.data?.error?.data?.data?.message ??
            this.scopedi18n.addCardHolderForm.errorMessage;
          this.$toast.error(message);
        })
        .finally(() => {
          this.addingCardholder = false;
        });
    },

    async persistSpendingLimits(payload: UpdateSpendingLimitsPayload) {
      this.updatingSpendingLimits = true;

      const result = await setSpendingLimits(
        this.$axios,
        this.$store.getters["auth/getAccountId"],
        payload
      );

      if (isErr(result)) {
        if (is422(result)) {
          this.$toast.error(
            values(result.err.data.error.data.errors)
              .map(join(". ") as any)
              .join(". ")
          );
        }
      }

      if (isOk(result)) {
        this.$toast.success(this.scopedi18n.spendLimits.apiMessages.success);

        await this.fetchCardholders();

        this.updatingSpendingLimits = false;
      }
    },
  },
});
