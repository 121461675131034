import {
  assoc,
  clamp,
  dec,
  defaultTo,
  equals,
  filter,
  find,
  fromPairs,
  inc,
  isEmpty,
  isNil,
  last,
  lt,
  path,
  pipe,
  prop,
  propEq,
  toPairs,
} from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { TrackingEvents } from "./tracking";
import { RootState } from "./index";
import { AppCookieNames, Stores } from "~/constants/ecomApi";
import thresholds from "~/constants/thresholds";
import { toRelative } from "~/lib/shared";
import { fetchBrDocument } from "~/services/bloomreach.service";
import { fetchVariations } from "~/services/products.service";
import {
  CategoryKey,
  ContentSearchData,
  FacetField,
  Product,
  ProductFilter,
} from "~/services/search.d";
import { fetchContentSearchDataApi } from "~/services/search.service";
import {
  attributesToRequest,
  SortType,
  sortValues,
} from "~/utils/config/search-config";
import { ServerSideFilterNames } from "~/utils/filters/index";

interface SearchState {
  searchApi: string;
  query: string;
  searchType: string;
  searchData: ContentSearchData | null;
  activeFilters: ProductFilter[];
  filterWasAdded: boolean;
  hierarchyCrumb: Array<CategoryKey | "">;
  productsPerPage: number;
  currentPage: number;
  totalPages: number;
  brandUrl: string;
  layout: string;
  loader: boolean;
  sortBy: SortType;
  productQuantities: any[];
  variationsModalVisible: boolean;
  variationsModalProductCode: string | null;
  variationsHasLoaded: boolean;
  variationsWanted: Record<string, number>;
  loadedVariations: any[];
  nextBusinessDayModalVisible: boolean;
}

export const state = (): SearchState => ({
  searchApi: "",
  // Query
  query: "",
  searchType: "keyword",
  // Bloomreach data
  searchData: null,
  // Filters
  activeFilters: [],
  filterWasAdded: false,
  hierarchyCrumb: [],
  // Pagination
  productsPerPage: 24,
  currentPage: 1,
  totalPages: 1,
  brandUrl: "https://s3.amazonaws.com/ts-website-content/tsuk/",
  // UI
  layout: "grid",
  loader: false,
  // Sort
  sortBy: "relevance",
  // Products
  productQuantities: [],
  // Variations
  variationsModalVisible: false,
  variationsModalProductCode: "",
  variationsHasLoaded: false,
  variationsWanted: {},
  loadedVariations: [],
  // Next business day
  nextBusinessDayModalVisible: false,
});

export const mutations: MutationTree<SearchState> = {
  setSearchApi: (state, payload) => {
    state.searchApi = payload;
  },
  // Query
  setQuery: (state, data) => {
    state.query = data;
  },
  setSearchType: (state, data) => {
    state.searchType = data;
  },
  // Bloomreach data
  setSearchData: (state, data) => {
    state.searchData = data;
    state.totalPages = data
      ? Math.ceil(data.response.numFound / state.productsPerPage)
      : 0;
  },
  updateActiveFilters: (state, data) => {
    state.filterWasAdded = state.activeFilters.length < data.length;
    state.activeFilters = data;
    state.currentPage = 1;
  },
  clearFilters: (state) => {
    state.activeFilters = [];
    state.hierarchyCrumb = [];
    state.currentPage = 1;
  },
  setHierarchyCrumb: (state, data) => {
    state.hierarchyCrumb = data;
  },
  // Pagination
  setProductsPerPage: (state, data) => {
    state.productsPerPage = data;
    state.currentPage = 1;
  },
  setTotalPages: (state, numFound) => {
    state.totalPages = Math.ceil(numFound / state.productsPerPage);
  },
  setPageNumber: (state, data) => {
    state.currentPage = parseInt(data, 10);
  },
  // UI
  setLayout: (state, data) => {
    state.layout = data;
  },
  setLoader: (state, data) => {
    state.loader = data;
  },
  // Sort
  setSortBy: (state, payload: SortType) => {
    if (!sortValues.includes(payload)) return;
    state.sortBy = payload;
    state.currentPage = 1;
  },
  setSortOnLoad: (state, payload: SortType) => {
    if (!sortValues.includes(payload)) return;
    state.sortBy = payload;
  },
  resetProductQuantities: (state) => {
    state.productQuantities = [];
  },
  // Products
  setQuantities: (state, data) => {
    const quantityState = state.productQuantities;
    const existingQuantity = quantityState.find(
      (product) => product.code === data.key
    );
    const quantity =
      data.value < 1 || data.value === "" ? 1 : parseInt(data.value);

    if (existingQuantity) {
      existingQuantity.qty = quantity;
      state.productQuantities = quantityState;
    } else {
      state.productQuantities.push({
        code: data.key,
        qty: quantity,
      });
    }
  },
  // Variations modal
  setVariationsModalVisibility: (state, { shown, productCode }) => {
    state.variationsModalVisible = shown;
    state.variationsModalProductCode = productCode;
  },
  setVariationsHasLoaded: (state, { hasLoaded }) => {
    state.variationsHasLoaded = hasLoaded;
  },
  setVariationsWanted: (state, [productCode, quantity]) => {
    state.variationsWanted = assoc(
      productCode,
      clamp(0, thresholds.trolley.MAX_ALLOWED_QUANTITY, quantity),
      state.variationsWanted
    );
  },
  clearVariationsWanted: (state) => {
    state.variationsWanted = {};
  },
  setLoadedVariations: (state, variations) => {
    state.loadedVariations = variations;
  },
  // Next business day
  setNextBusinessDayModalVisibility: (state, shown: boolean) => {
    state.nextBusinessDayModalVisible = shown;
  },
};

export const getters: GetterTree<SearchState, RootState> = {
  // Query
  getQuery: (state) => state.query,
  getSearchType: (state) => state.searchType,
  // Filters
  getActiveFilters: (state) => state.activeFilters,
  isCategoryFilterActive: (_state, getters) => {
    return getters.getActiveFilters.find(
      (el: ProductFilter) => el.name === ServerSideFilterNames.Category
    );
  },
  filterWasAdded: (state) => state.filterWasAdded,
  getSearchData: (state) => state.searchData,
  getBrandUrl: (state) => state.brandUrl,
  isFilterActive:
    (state) =>
    (givenFilter: ProductFilter): boolean => {
      return state.activeFilters.some((filter: ProductFilter) =>
        equals(filter, givenFilter)
      );
    },
  getFilters: (state) => {
    if (state.searchData?.facetCounts?.facetFields == null) return [];
    return Object.entries(state.searchData?.facetCounts.facetFields)
      .filter(([_name, values]: [string, FacetField[]]) => values.length)
      .map(([name, values]: [string, FacetField[]]) => ({
        name,
        values,
      }));
  },
  getHierarchyCrumb: (state) => state.hierarchyCrumb ?? [],

  getCategories: (state) =>
    state.searchData?.facetCounts.facetFields.category ?? [],

  getStats: (state) => state.searchData?.stats?.statsFields ?? null,
  getProductChannel: (state) => {
    if (state.searchData?.response == null) return [];
    return [
      ...new Set(
        (state.searchData.response.docs as Product[]).map(
          (doc: Product) => doc.channel
        )
      ),
    ];
  },
  // Pagination
  getProductsPerPage: (state): number => state.productsPerPage,
  getNumberOfResults: (state) => state.searchData?.response.numFound ?? 0,
  getCurrentPage: (state): number => state.currentPage,
  getCurrentPageZeroBased: (state): number => state.currentPage - 1,
  getCurrentPageItemNumberZeroBased: (_, getters) =>
    getters.getCurrentPageZeroBased * getters.getProductsPerPage,
  getTotalPages: (state): number => state.totalPages,
  // UI
  getLayout: (state) => state.layout,
  getLoader: (state) => state.loader,
  // Sort
  getSortBy: (state) => state.sortBy,

  // Loaded yet
  hasSearchLoaded: (state) => !(state.searchData?.response == null),

  // Products
  getProductCodes: (state) =>
    state.searchData?.response.docs != null
      ? (state.searchData?.response.docs as Product[])?.map(
          (product: Product) => product.pid
        ) ?? []
      : [],
  getCampaign: (state) => state.searchData?.campaign ?? {},
  getProducts: (state) => state.searchData?.response.docs ?? [],
  getProductByCode: (state) => (code: string) =>
    ((state.searchData?.response.docs ?? []) as Product[]).find(
      (data) => data.pid === code
    ),
  findQuantities: (state) => (productCode: string, minValue: Number) => {
    const product = state.productQuantities.find(
      (index) => index.code === productCode
    );
    if (product) return product.qty;
    return minValue;
  },
  getSearchParams(state, getters, _rootState, rootGetters) {
    return ({ isSsr = false, url = "" }: { isSsr: boolean; url: string }) => {
      const uniqueActiveFilters = [
        ...new Set(
          getters.getActiveFilters.map((filter: ProductFilter) => filter.name)
        ),
      ];

      const activeFiltersJoined =
        uniqueActiveFilters.length > 0
          ? uniqueActiveFilters
              .map((filterName) => {
                const filterValue: string = getters.getActiveFilters
                  .filter((filter: ProductFilter) => filter.name === filterName)
                  .map((activeFilter: ProductFilter) => activeFilter.value)
                  .join('" OR "');
                return `${filterName as string}:"${filterValue}"`;
              })
              .join(",")
          : "";

      return {
        q: getters.getQuery,
        fl: attributesToRequest.join(","),
        rows: state.productsPerPage,
        start:
          state.currentPage * state.productsPerPage - state.productsPerPage,
        url: isSsr ? url : document.location.href,
        ref_url: isSsr ? "" : document.referrer,
        search_type: getters.getSearchType,
        ...((rootGetters["auth/isAuthenticated"] as boolean)
          ? { user_id: rootGetters["auth/getUser"].id }
          : {}),
        ...(state.sortBy !== "relevance" ? { sort: state.sortBy } : {}),
        ...(uniqueActiveFilters.length > 0 ? { fq: activeFiltersJoined } : {}),
      };
    };
  },
  isVariationsModalVisible: (state) => state.variationsModalVisible,
  getVariationsModalProductCode: (state) => state.variationsModalProductCode,
  getWantedVariationByCode: (state) => (productCode: string) =>
    state.variationsWanted[productCode] ?? 0,
  getWantedVariations: pipe(
    prop("variationsWanted"),
    toPairs,
    filter(pipe(last, lt(0))),
    fromPairs
  ),
  isVariationsLoaded: propEq("variationsHasLoaded", true),
  getLoadedVariations: prop("loadedVariations"),
  getLoadedVariationByCode: (state) => (productCode: string) =>
    find(propEq("code", productCode), prop("loadedVariations", state)),
  brRedirect: path(["searchData", "keywordRedirect", "redirected url"]),
};

export const actions: ActionTree<SearchState, RootState> = {
  async fetchContentSearchData(
    { commit, getters, state, rootGetters },
    params = { isSsr: false, url: null, $config: {} }
  ) {
    commit("setLoader", true);

    const searchParams = getters.getSearchParams(params);
    const visitorId = this.$cookies.get(AppCookieNames.TsSessionId);

    let boost = null;
    const branchId = rootGetters["branch/selectedBranchId"];

    // Apply good stock availability if branch set.
    if (branchId) {
      boost = `available_stores:${branchId}`;
    }

    let brNativeEnabled = false;
    let brLlmPrecisionModeEnabled = false;

    if (!isNil(this.$cookies.get("_ts_use_br_native"))) {
      brNativeEnabled = true;
    }

    if (!isNil(this.$cookies.get("_ts_use_br_llm_precision_mode"))) {
      brLlmPrecisionModeEnabled = true;
    }

    const searchData = await fetchContentSearchDataApi(this.$axios, {
      searchApi: state.searchApi,
      crsEnabled: searchParams.search_type === "keyword",
      queryParams: {
        ...searchParams,
        ...(visitorId ? { ts_visitor_id: visitorId } : {}),
        ...(boost ? { boost } : {}),
        ...(brNativeEnabled ? { "merchandising_rules.disable": "recall" } : {}),
        ...(brLlmPrecisionModeEnabled
          ? { "query.precision": "llm_based_precision" }
          : {}),
        _br_uid_2: this.$cookies.get("_br_uid_2"),
      },
    });

    if (!isNil(searchData)) {
      if (
        !params.isSsr &&
        !isEmpty(searchData?.keywordRedirect["redirected url"]) &&
        !isNil(searchData?.keywordRedirect["redirected url"])
      ) {
        this.$router.push(
          toRelative(searchData.keywordRedirect["redirected url"])
        );

        return { clientCategoryRedirect: true };
      }

      if (searchData?.metadata?.attribution_token) {
        this.$cookies.set(
          "_crs_attribution_token",
          searchData?.metadata?.attribution_token
        );
      } else {
        this.$cookies.remove("_crs_attribution_token");
      }

      if (searchData?.campaign) {
        searchData.campaign = (await fetchBrDocument(
          "CAMPAIGNS",
          searchData.campaign.htmlText,
          params.$config.brxmContentEndpoint
        )) as any;
      }

      searchData.metadata.loadedOnServer = !!process.server;

      commit("setSearchData", searchData);
      commit("setTotalPages", searchData.response.numFound);
      commit("setLoader", false);
      if (!(params.isSsr as boolean)) {
        // Track Product Impressions
        let event;
        const obj = {
          products: searchData.response.docs,
          query: getters.getQuery,
        };
        if (
          !isNil(window.CustomEvent) &&
          typeof window.CustomEvent === "function"
        ) {
          event = new window.CustomEvent("product-hits-change", {
            detail: obj,
          });
        } else {
          event = document.createEvent("CustomEvent");
          event.initCustomEvent("product-hits-change", true, true, {
            detail: obj,
          });
        }
        document.body.dispatchEvent(event);

        commit(
          "tracking/addTrackingEvent",
          {
            type: TrackingEvents.SearchOrCategory,
            data: {
              products: getters.getProducts,
              type: "Search",
              category: null,
            },
          },
          { root: true }
        );
      }
      return searchData;
    }
    return null;
  },
  updateHierarchyCrumb: async ({ state, commit }, data) => {
    // From
    const from = state.hierarchyCrumb;
    let fromStringified = JSON.stringify(from);
    fromStringified = fromStringified.substring(1, fromStringified.length - 1);
    // To
    const to = data;
    let toStringified = JSON.stringify(to);
    toStringified = toStringified.substring(1, toStringified.length - 1);
    // Check if from is under hierarchy of to
    return await new Promise((resolve) => {
      if (fromStringified.includes(toStringified)) {
        commit("setHierarchyCrumb", to.slice(0, -1));
      } else {
        commit("setHierarchyCrumb", data);
      }
      resolve(state.hierarchyCrumb[state.hierarchyCrumb.length - 1]);
    });
  },
  async updateVariationsModal(
    { commit },
    {
      shown,
      productCode,
      siteId,
    }: { shown: boolean; productCode: string; siteId: string }
  ) {
    commit("setVariationsModalVisibility", { shown, productCode });
    commit("setVariationsHasLoaded", { hasLoaded: false });

    await fetchVariations(this.$axios, productCode, [siteId, Stores.Web])
      .then((variations) => {
        commit("setLoadedVariations", variations);
        commit("setVariationsHasLoaded", { hasLoaded: true });
      })
      .catch(() => {
        commit("setLoadedVariations", []);
        commit("setVariationsHasLoaded", { hasLoaded: true });
      });
  },
  closeVariationsModal: (context) => {
    context.commit("setVariationsModalVisibility", {
      shown: false,
      productCode: null,
    });
    context.commit("clearVariationsWanted");
  },
  incrementVariationsWanted({ commit, state }, productCode: string) {
    commit("setVariationsWanted", [
      productCode,
      defaultTo(1, inc(state.variationsWanted?.[productCode])),
    ]);
  },
  decrementVariationsWanted({ commit, state }, productCode: string) {
    commit("setVariationsWanted", [
      productCode,
      defaultTo(1, dec(state.variationsWanted?.[productCode])),
    ]);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
