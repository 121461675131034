import {
  allPass,
  always,
  complement,
  compose,
  either,
  equals,
  filter,
  is,
  isEmpty,
  isNil,
  not,
  reject,
} from "ramda";
import type { PromoBlocks } from "~/components/UI/banners/PromoBlocks.d";

export const isNotLastItemOfList = (index: number, list: any[]) =>
  list.length - 1 !== index;
export const isLastItemOfList = compose(not, isNotLastItemOfList);

export const promoSlideGroupToHref = (
  desktopSlides: any,
  mobileSlides: any
): PromoBlocks => {
  function getData(obj: any) {
    if (obj) {
      return obj.promoSlide.map((slides: any) => {
        return slides.imageAndLink.map((slide: any) => {
          return {
            src: slide.imageUrl,
            alt: slide.imageAltText,
            href: slide.linkUrl,
          };
        });
      });
    }
  }

  return {
    desktopSlides: { slides: getData(desktopSlides) },
    mobileSlides: { slides: getData(mobileSlides) },
  };
};

export const asyncCallWithTimeout = async <T>(
  asyncPromise: Promise<T>,
  timeLimit: number
  // eslint-disable-next-line require-await
): Promise<T> => {
  let timeoutHandle: NodeJS.Timeout;

  const timeoutPromise = new Promise<T>((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () => reject(new Error("Async call timeout limit reached")),
      timeLimit
    );
  });

  return Promise.race([asyncPromise, timeoutPromise]).then((result: T) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

export const just = always;
export const constantly = always;

export const isNumber = is(Number);

export const isTrue = equals(true);
export const isFalse = complement(isTrue);
export const isNotFalse = complement(equals(false));
export const isUndefined = equals(undefined);
export const isEmptyOrNil = either(isEmpty, isNil);

export const isNotEmpty = compose(not, either(isNil, isEmpty));
export const isNotNil = complement(isNil);
export const isNotEmptyOrNil = complement(isEmptyOrNil);

export const filterNotEmpty = filter(isNotEmpty);
export const filterFalse = filter(isNotFalse);
export const filterFalsy = filter(allPass([isNotNil, isNotEmpty, isNotFalse]));

export const rejectEmptyOrNil = reject(isEmptyOrNil);
export const rejectUndefined = reject<any>(isUndefined);
