<template>
  <li class="border-b border-grey-3 relative" :class="itemClasses">
    <slot />
    <ChevronIcon
      classes="w-6 h-6 absolute top-1/2 -translate-y-1/2 right-4"
      direction="right"
    />
  </li>
</template>

<script>
import { ChevronIcon } from "~/components/UI/icons";
export default {
  name: "MenuItem",
  components: {
    ChevronIcon,
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    itemClasses() {
      return this.isActive
        ? "font-bold bg-blue hover:bg-blue text-white"
        : "bg-white hover:bg-grey-1";
    },
  },
};
</script>
