import axios from "axios";
import { handleError } from "~/services/helpers";

const ENDPOINTS = {
  HOME: (brContentUrl: string, document: string) =>
    `${brContentUrl}/${document}`,
  TRADE_ACCOUNT_PROMOS: (brContentURL: string, document: string) =>
    `${brContentURL}/promotions/trade-account/${document}`,
  PRODUCTS: (brContentURL: string, document: string) =>
    `${brContentURL}/product-page/products/${document}`,
  PRODUCT_PAGE: (brContentURL: string, document: string) =>
    `${brContentURL}/product-page/${document}`,
  PRODUCT_LISTING_PAGE: (brContentURL: string, document: string) =>
    `${brContentURL}/product-listing-page/categories/${document}`,
  DEPARTMENT_PAGE: (brContentURL: string, document: string) =>
    `${brContentURL}/top-level-category-page/categories/${document}`,
  CAMPAIGNS: (brContentURL: string, document: string) =>
    `${brContentURL}/campaigns/${document}`,
  ERROR_PAGE: (brContentURL: string, document: string) =>
    `${brContentURL}/error-pages/${document}`,
  SITE_MESSAGES: (brContentURL: string, document: string) =>
    `${brContentURL}/site-messaging/${document}`,
  FOOTER: (brContentURL: string, document: string) =>
    `${brContentURL}/footer/${document}`,
  USP_BANNER: (brContentURL: string, document: string) =>
    `${brContentURL}/usp-banner/${document}`,
};

export enum BloomreachFolderPaths {
  Home = "HOME",
  TradeAccountPromos = "TRADE_ACCOUNT_PROMOS",
  Products = "PRODUCTS",
  ProductPage = "PRODUCT_PAGE",
  ProductListingPage = "PRODUCT_LISTING_PAGE",
  DepartmentPage = "DEPARTMENT_PAGE",
  ErrorPage = "ERROR_PAGE",
  SiteMessages = "SITE_MESSAGES",
  Footer = "FOOTER",
  USPBanner = "USP_BANNER",
}

export type DocumentPathKey = keyof typeof ENDPOINTS;

export interface BrDocumentResponse {
  document: {
    $ref: string;
  };
  content: {
    [key: string]: {
      data: any;
    };
  };
}

export const getBrDocument = async (
  documentPath: DocumentPathKey,
  document: string,
  brContentURL: string,
  previewToken?: string
) => {
  const url = `${ENDPOINTS[documentPath](brContentURL, document)}${
    previewToken ? "?preview-token=" + previewToken : ""
  }`;
  try {
    const response = await fetch(url);
    if (response.status >= 200 && response.status <= 299) {
      return await response.json();
    }
    throw response;
  } catch (error) {
    handleError(error);
  }
};

export const getBrDocumentContent = (document: any) =>
  (Object.values(document?.content)?.[0] as any)?.data ?? null;

export const getBrExperiencePage = async (config: any) => {
  try {
    // Need to maintain axios here as the Bloomreach experience manager requires it
    return await axios(config);
  } catch (error) {
    handleError(error);
  }
};
