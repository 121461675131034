import locale5f43b444 from '../../utils/translations/en-GB.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true,"fallbackLocale":"en-GB"},
  vueI18nLoader: false,
  locales: [{"code":"nl-BE","iso":"nl-BE","file":"nl-BE.ts","name":"Dutch in Belgium"},{"code":"fr-FR","iso":"fr-FR","file":"fr-FR.ts","name":"French in France"},{"code":"en-FR","iso":"en-FR","file":"en-FR.ts","name":"English in France"},{"code":"en-GB","iso":"en-GB","file":"en-GB.ts","name":"English in United Kingdom"},{"code":"nl-NL","iso":"nl-NL","file":"nl-NL.ts","name":"Dutch in Netherlands"},{"code":"en-NL","iso":"en-NL","file":"en-NL.ts","name":"English in Netherlands"}],
  defaultLocale: "en-GB",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: false,
  strategy: "no_prefix",
  lazy: {"skipNuxtState":true},
  langDir: "utils/translations/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"toolstation-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: true,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"nl-BE","iso":"nl-BE","file":"nl-BE.ts","name":"Dutch in Belgium"},{"code":"fr-FR","iso":"fr-FR","file":"fr-FR.ts","name":"French in France"},{"code":"en-FR","iso":"en-FR","file":"en-FR.ts","name":"English in France"},{"code":"en-GB","iso":"en-GB","file":"en-GB.ts","name":"English in United Kingdom"},{"code":"nl-NL","iso":"nl-NL","file":"nl-NL.ts","name":"Dutch in Netherlands"},{"code":"en-NL","iso":"en-NL","file":"en-NL.ts","name":"English in Netherlands"}],
  localeCodes: ["nl-BE","fr-FR","en-FR","en-GB","nl-NL","en-NL"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "n",
  3: "l",
  4: "-",
  5: "B",
  6: "E",
  7: ".",
  8: "t",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "u",
  17: "t",
  18: "i",
  19: "l",
  20: "s",
  21: "/",
  22: "t",
  23: "r",
  24: "a",
  25: "n",
  26: "s",
  27: "l",
  28: "a",
  29: "t",
  30: "i",
  31: "o",
  32: "n",
  33: "s",
  34: "/",
  35: "n",
  36: "l",
  37: "-",
  38: "B",
  39: "E",
  40: ".",
  41: "t",
  42: "s",
  43: "\"",
  44: ",",
  45: "\"",
  46: "f",
  47: "r",
  48: "-",
  49: "F",
  50: "R",
  51: ".",
  52: "t",
  53: "s",
  54: "\"",
  55: ":",
  56: "\"",
  57: ".",
  58: ".",
  59: "/",
  60: "u",
  61: "t",
  62: "i",
  63: "l",
  64: "s",
  65: "/",
  66: "t",
  67: "r",
  68: "a",
  69: "n",
  70: "s",
  71: "l",
  72: "a",
  73: "t",
  74: "i",
  75: "o",
  76: "n",
  77: "s",
  78: "/",
  79: "f",
  80: "r",
  81: "-",
  82: "F",
  83: "R",
  84: ".",
  85: "t",
  86: "s",
  87: "\"",
  88: ",",
  89: "\"",
  90: "e",
  91: "n",
  92: "-",
  93: "F",
  94: "R",
  95: ".",
  96: "t",
  97: "s",
  98: "\"",
  99: ":",
  100: "\"",
  101: ".",
  102: ".",
  103: "/",
  104: "u",
  105: "t",
  106: "i",
  107: "l",
  108: "s",
  109: "/",
  110: "t",
  111: "r",
  112: "a",
  113: "n",
  114: "s",
  115: "l",
  116: "a",
  117: "t",
  118: "i",
  119: "o",
  120: "n",
  121: "s",
  122: "/",
  123: "e",
  124: "n",
  125: "-",
  126: "F",
  127: "R",
  128: ".",
  129: "t",
  130: "s",
  131: "\"",
  132: ",",
  133: "\"",
  134: "e",
  135: "n",
  136: "-",
  137: "G",
  138: "B",
  139: ".",
  140: "t",
  141: "s",
  142: "\"",
  143: ":",
  144: "\"",
  145: ".",
  146: ".",
  147: "/",
  148: "u",
  149: "t",
  150: "i",
  151: "l",
  152: "s",
  153: "/",
  154: "t",
  155: "r",
  156: "a",
  157: "n",
  158: "s",
  159: "l",
  160: "a",
  161: "t",
  162: "i",
  163: "o",
  164: "n",
  165: "s",
  166: "/",
  167: "e",
  168: "n",
  169: "-",
  170: "G",
  171: "B",
  172: ".",
  173: "t",
  174: "s",
  175: "\"",
  176: ",",
  177: "\"",
  178: "n",
  179: "l",
  180: "-",
  181: "N",
  182: "L",
  183: ".",
  184: "t",
  185: "s",
  186: "\"",
  187: ":",
  188: "\"",
  189: ".",
  190: ".",
  191: "/",
  192: "u",
  193: "t",
  194: "i",
  195: "l",
  196: "s",
  197: "/",
  198: "t",
  199: "r",
  200: "a",
  201: "n",
  202: "s",
  203: "l",
  204: "a",
  205: "t",
  206: "i",
  207: "o",
  208: "n",
  209: "s",
  210: "/",
  211: "n",
  212: "l",
  213: "-",
  214: "N",
  215: "L",
  216: ".",
  217: "t",
  218: "s",
  219: "\"",
  220: ",",
  221: "\"",
  222: "e",
  223: "n",
  224: "-",
  225: "N",
  226: "L",
  227: ".",
  228: "t",
  229: "s",
  230: "\"",
  231: ":",
  232: "\"",
  233: ".",
  234: ".",
  235: "/",
  236: "u",
  237: "t",
  238: "i",
  239: "l",
  240: "s",
  241: "/",
  242: "t",
  243: "r",
  244: "a",
  245: "n",
  246: "s",
  247: "l",
  248: "a",
  249: "t",
  250: "i",
  251: "o",
  252: "n",
  253: "s",
  254: "/",
  255: "e",
  256: "n",
  257: "-",
  258: "N",
  259: "L",
  260: ".",
  261: "t",
  262: "s",
  263: "\"",
  264: "}",
}

export const localeMessages = {
  'en-GB.ts': () => Promise.resolve(locale5f43b444),
  'nl-BE.ts': () => import('../../utils/translations/nl-BE.ts' /* webpackChunkName: "lang-nl-BE.ts" */),
  'fr-FR.ts': () => import('../../utils/translations/fr-FR.ts' /* webpackChunkName: "lang-fr-FR.ts" */),
  'en-FR.ts': () => import('../../utils/translations/en-FR.ts' /* webpackChunkName: "lang-en-FR.ts" */),
  'nl-NL.ts': () => import('../../utils/translations/nl-NL.ts' /* webpackChunkName: "lang-nl-NL.ts" */),
  'en-NL.ts': () => import('../../utils/translations/en-NL.ts' /* webpackChunkName: "lang-en-NL.ts" */),
}
