import type { Context } from "@nuxt/types";
import jsonwebtoken, {
  JsonWebTokenError,
  TokenExpiredError,
} from "jsonwebtoken";
import { prop } from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AuthStateUser } from "./auth";
import { RootState } from "./index";
import { AppCookieNames } from "~/constants/ecomApi";
import type { CustomerApiError } from "~/services/customers.d";
import {
  createGuestCustomer,
  fetchCustomerById,
} from "~/services/customers.service";
import { camelizeKeys } from "~/utils/utils";

interface GuestState {
  "guest-checkout-session-unpacked": string | null;
  guest: AuthStateUser | null;
  guestEmail: string | null;
  showGuestForm: boolean;
}

export const state = (): GuestState => ({
  "guest-checkout-session-unpacked": null,
  guest: null,
  guestEmail: null,
  showGuestForm: false,
});

export const getters: GetterTree<GuestState, RootState> = {
  guestCheckoutSession: prop("guest-checkout-session-unpacked"),
  getGuest: (state: GuestState) => state.guest,
  getGuestEmail: (state: GuestState) => state.guestEmail,
  getGuestId: (state: GuestState): string | null => state.guest?.id ?? null,
  getGuestCustomerJwtToken: (state: GuestState) => state.guest?.token,
  getShowGuestForm: (state: GuestState): boolean => state.showGuestForm,
  /**
   * @deprecated
   */
  isCustomerGuest: (state: GuestState): boolean => !!state.guest?.token,
  isAuthenticated: (state: GuestState): boolean => !!state.guest?.token,
};

export const mutations: MutationTree<GuestState> = {
  setGuestCheckoutSession: (state, id: string) => {
    state["guest-checkout-session-unpacked"] = id;
  },
  setGuest: (state, value: AuthStateUser) => {
    state.guest = camelizeKeys(value);
  },
  setGuestEmail: (state, email: string) => {
    state.guestEmail = email;
  },
  resetGuest: (state) => {
    state.guest = null;
    state.guestEmail = null;
  },
  setShowGuestForm: (state, shown: boolean) => {
    state.showGuestForm = shown;
  },
};

export const actions: ActionTree<GuestState, RootState> = {
  setGuestAxiosHeaders(_, payload: string): void {
    this.$axios.setHeader("X-Toolstation-Customer-Id", payload);
    this.$cookies.set(AppCookieNames.GuestCustomerJWT, payload);
  },
  clearGuestUser({ commit }, { app, $axios }: Context) {
    commit("resetGuest");
    delete $axios.defaults.headers.common["X-Toolstation-Customer-Id"];
    app.$cookies.remove(AppCookieNames.GuestCustomerJWT);
  },
  async setGuestUserFromCookie(
    { commit },
    { app, $config, $axios, $log }: Context
  ) {
    const guestJwt = app.$cookies.get(AppCookieNames.GuestCustomerJWT);
    if (!guestJwt) return;

    try {
      const { customer_id: guestId } = jsonwebtoken.verify(
        guestJwt,
        $config.jwtSecret
      ) as any;

      $axios.setHeader("X-Toolstation-Customer-Id", guestJwt);
      commit("setGuest", await fetchCustomerById($axios, guestId));
    } catch (error: any) {
      if (error instanceof TokenExpiredError) {
        $log.warn("🚨 Expired JWT Token");
      } else if (error instanceof JsonWebTokenError) {
        $log.debug(
          `🚨 Invalid JWT Token - cause "${
            error.cause?.message ?? "unknown"
          }", message: "${error.message}", name: "${error.name}"`
        );
      } else if (
        error instanceof Error &&
        "response" in error &&
        "config" in error
      ) {
        // deal with API error?
        const apiError = error as CustomerApiError;
        $log.error(`🚨 API Error: ${apiError.response.data as string}`);
        $log.error(`🚨 Status: ${apiError.response.status as string}`);
        $log.error(`🚨 StatusText: ${apiError.response.statusText as string}`);
        $log.error(`🚨 URL: ${apiError.config.url as string}`);
      } else {
        $log.error(`🚨 ERROR: ${error as string}`);
      }

      app.$cookies.remove(AppCookieNames.GuestCustomerJWT);
    }
  },
  async createGuestAccount({ dispatch, commit }, guestData) {
    const guest = await createGuestCustomer(this.$axios, guestData);
    dispatch("setGuestAxiosHeaders", guest.token);
    commit("setGuest", guest);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
