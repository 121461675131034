import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { NuxtCookies } from "cookie-universal-nuxt";
import { isEmpty } from "ramda";
import { handleError } from "./helpers";
import { ContentSearchApiData, ContentSearchData } from "./search.d";
import { ProductRecommendation } from "~/types/productRecommendation";
import {
  SearchData,
  SearchDataAPIResponse,
  SuggestionGroupAPIResponse,
} from "~/types/searchBar";
import { camelizeKeys, randomRequestId } from "~/utils/utils";

export function transformSearchDataFromApi(
  object: SearchDataAPIResponse
): SearchData {
  const getSearchSuggestionsArray = (
    suggestionGroup: SuggestionGroupAPIResponse
  ): any[] => {
    if (suggestionGroup?.searchSuggestions === undefined) return [];
    if (isEmpty(suggestionGroup.searchSuggestions)) return [];
    if (Array.isArray(suggestionGroup.searchSuggestions))
      return suggestionGroup.searchSuggestions;
    return Object.values(suggestionGroup.searchSuggestions);
  };

  return {
    ...object,
    suggestionGroups: object.suggestionGroups?.map((suggestionGroup) => {
      return {
        ...suggestionGroup,
        searchSuggestions:
          getSearchSuggestionsArray(suggestionGroup)?.map(
            (searchSuggestion) => ({
              ...searchSuggestion,
              formattedPrices: JSON.parse(searchSuggestion.formattedPrices),
            })
          ) ?? [],
      };
    }),
  };
}

export const getSearchData = async (
  $axios: NuxtAxiosInstance,
  $cookies: NuxtCookies,
  {
    query,
    searchApi,
    crsEnabled,
  }: { query: string; searchApi: string; crsEnabled: boolean }
): Promise<SearchData | null> => {
  let result: SearchData | null = null;
  try {
    const searchQuery = {
      q: query,
      request_id: randomRequestId(),
      _br_uid_2: $cookies.get("_br_uid_2"),
      url: document.location.href,
      ref_url: document.referrer,
      catalog_views: "toolstation:gb", // TODO - needs to come from regional config
    };
    const data = await $axios.$get<SearchDataAPIResponse>(
      `${searchApi}/suggest${crsEnabled ? "/crs" : ""}`,
      {
        params: searchQuery,
      }
    );
    result = transformSearchDataFromApi(data);
  } catch (error) {
    handleError(error);
  }
  return camelizeKeys(result);
};

interface RecommendationsQuery {
  categoryId?: string;
  itemIds?: string | [];
  query?: string;
}

export const getRecommendations = async (
  context: any,
  pathway: string,
  widgetId: string,
  params: RecommendationsQuery | null
): Promise<any> => {
  const specificToRequest = (): Object => {
    switch (pathway) {
      case "item":
        return { item_ids: params?.itemIds };
      case "category":
        return { cat_id: params?.categoryId };
      case "keyword":
      case "personalization":
        return { query: params?.query };
      case "global":
        return {};
    }
    return {};
  };

  const req = context.$ssrContext?.req;
  const currentPath = `${
    context.$store?.getters?.domain ?? context.store?.getters?.domain
  }${(context.$route ?? context.route).fullPath}`;
  const referrerPath = req
    ? req?.headers?.referer
    : process.client
      ? document?.referrer
      : context.route.path;

  try {
    return await context.$axios
      .get(`${context.$config.searchApi}/pathways/${pathway}/${widgetId}`, {
        params: {
          account_id: "6523", // TODO - needs to come from regional config
          domain_key: "toolstation", // TODO - needs to come from regional config
          _br_uid_2: context.$cookies.get("_br_uid_2") || false,
          url: currentPath,
          ref_url: referrerPath,
          view_id: context.$config.localeInstance.toLowerCase(),
          request_id: randomRequestId(),
          ...specificToRequest(),
          fields: [
            "pid",
            "title",
            "taxonomy",
            "brand",
            "sale_price",
            "promotion",
            "thumb_image",
            "url",
            "formattedPrices",
            "prices",
            "ts_reviews",
            "assettr",
            "name_type",
            "name_qty",
            "quantityminimum",
            "samedaydelivery",
            "channel",
            "numberofreviews",
            "description",
            "price",
            "weboverlaytext",
            "slug",
          ].join(","),
        },
      })
      .then((val: any) => {
        // Bloomreach event tracking pixel (https://documentation.bloomreach.com/discovery/reference/recommendations-and-pathways-integration-overview)
        interface widgetViewData {
          wrid: string;
          wq: string;
          wid: string;
          wty: string;
        }
        const { rid, id, type } = val.data.metadata.widget;
        const widget_view_data = {} as widgetViewData;
        if (params?.query) widget_view_data.wq = params.query;
        widget_view_data.wrid = rid;
        widget_view_data.wid = id;
        widget_view_data.wty = type;

        return {
          data: camelizeKeys(val.data.response.docs),
          tracking: widget_view_data,
        };
      });
  } catch (error) {
    handleError(error);
  }
};
export const hasRecommendations = (data: any) => data.data.length;

export const getGoogleRecommendations = async (
  context: any,
  recommendationType: string,
  userEvent: object,
  branchId: string
): Promise<ProductRecommendation[]> => {
  try {
    return await context.$axios
      .post(
        `${context.$config.searchApi}/pathways/crs/recs/${recommendationType}?branch=${branchId}`,
        {
          userEvent,
        }
      )
      .then((val: any) => {
        return val.data.results.map((res: any) => {
          return {
            ...camelizeKeys(res),
          } as ProductRecommendation;
        });
      });
  } catch (error) {
    handleError(error);

    return [];
  }
};

const mapContentSearchData = (
  data: ContentSearchApiData
): ContentSearchData => {
  // Construct relative product path if slug available.
  const docs = data.response.docs.map((doc) => ({
    ...doc,
    url: doc.slug && doc.pid ? `/${doc.slug}/p${doc.pid}` : doc.url,
  }));

  return {
    campaign: data.campaign,
    categoryMap: data.category_map,
    didYouMean: data.did_you_mean,
    facetCounts: {
      facetFields: data.facet_counts.facet_fields,
      facetQueries: data.facet_counts.facet_queries,
      facetRanges: data.facet_counts.facet_ranges,
    },
    response: camelizeKeys({
      ...data.response,
      docs,
    }),
    stats: camelizeKeys(data.stats),
    keywordRedirect: data.keywordRedirect ?? {},
    metadata: data.metadata ?? {},
  };
};

export const fetchContentSearchDataApi = async (
  $axios: NuxtAxiosInstance,
  {
    searchApi,
    crsEnabled,
    queryParams,
  }: {
    searchApi: string;
    crsEnabled: boolean;
    queryParams: { [key: string]: string };
  }
): Promise<ContentSearchData | null> => {
  let searchData: ContentSearchData | null = null;
  try {
    const result = await $axios.$get(
      `${searchApi}/search${crsEnabled ? "/crs" : ""}`,
      {
        params: {
          request_id: randomRequestId(),
          domain_key: "toolstation", // TODO - needs to come from regional config
          view_id: "gb", // TODO - needs to come from regional config
          request_type: "search",
          stats_field: "price,channel",
          "f.category.facet.prefix": "/root,Home/", // Omits none product specific categories (e.g. brands, deals, clearance)
          ...queryParams,
        },
      }
    );
    searchData = mapContentSearchData(result as ContentSearchApiData);
  } catch (error) {
    return handleError(error);
  }
  return searchData;
};

export default { getSearchData };
