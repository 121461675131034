
import Vue from "vue";
import { fromToolstationCdnUrl } from "~/utils/fromToolstationCdn";

export default Vue.extend({
  name: "DefaultButton",
  props: {
    id: { type: String, required: false, default: "" },
    // When set to true, places the component in the active state with active styling
    active: { type: Boolean, default: false },
    // When set to false, the hover style will be omited
    hover: { type: Boolean, default: true },
    // When set to true, disables the component's functionality and places it in a disabled state
    disabled: { type: Boolean, default: false },
    // <BaseLink> prop: Denotes the target URL of the link for standard a tag links
    path: { type: String, required: false, default: undefined },
    // <BaseLink> prop: Denotes if it is an external URL or not
    external: { type: Boolean, required: false, default: undefined },
    // Denotes icon inside button
    withIcon: { type: String, required: false, default: "" },
    // Set the size of the component
    size: {
      type: String,
      default: undefined,
      // "small / full" are the only value used in the new style, the others are legacy
      validator(value) {
        return ["xs", "sm", "md", "lg", "full", "small", "tiny"].includes(
          String(value)
        );
      },
    },
    // Renders the button as a pill or with non-rounded corners
    appearance: {
      type: String,
      required: false,
      default: "rounded-square",
      validator(value) {
        return ["standard", "pill", "squared", "rounded-square"].includes(
          String(value)
        );
      },
    },
    // Specify the HTML tag to render instead of the default tag
    tag: {
      type: String,
      default: "button",
      validator(value) {
        return ["button", "a"].includes(String(value));
      },
    },
    // The value to set the button's 'type' attribute to.
    type: {
      type: String,
      default: "button",
      validator(value) {
        return ["button", "submit", "reset"].includes(String(value));
      },
    },
    // Applies one of the design system theme color variants to the component
    variant: {
      type: String,
      default: "primary",
      validator(value) {
        return [
          "primary",
          "secondary",
          "tertiary",
          "quaternary",
          "none",
        ].includes(String(value));
      },
    },
    uppercase: {
      type: Boolean,
      default: false,
      // Apply a font weight
    },
    weight: {
      type: String,
      default: "normal",
      validator(value) {
        return ["normal", "thin"].includes(String(value));
      },
    },
    width: {
      type: String,
      default: "normal",
      validator(value) {
        return ["normal", "consistent", "full"].includes(String(value));
      },
    },
    height: {
      type: String,
      default: undefined,
    },
    iconClasses: {
      type: String,
      default: "h-[13px]",
    },
    legacy: {
      type: Boolean,
      default: false,
    },
    textAlignment: {
      type: String,
      default: "center",
      validator(value) {
        return ["left", "center", "right"].includes(String(value));
      },
    },
    shrink: {
      type: Boolean,
      default: false,
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedTag() {
      if (this.path) return "NuxtLink";

      return this.tag;
    },
    classes() {
      return {
        ...(this.variant !== "none"
          ? {
              btn: true,
              // New Button styling
              [`${this.variant}`]: this.variant && !this.legacy,
              [`${this.size}`]: this.size && !this.legacy,
              [`${this.textAlignment}`]: this.textAlignment && !this.legacy,
              // Legacy prop/class styling below
              // TODO: these can all be removed when we move it over to new styles completely.
              legacy: this.legacy,
              [`btn--${this.variant}`]: this.variant && this.legacy,
              [`btn--${this.size}`]: this.size && this.legacy,
              "is-active": this.active && this.legacy,
              "rounded-full": this.appearance === "pill" && this.legacy,
              "rounded-none": this.appearance === "squared" && this.legacy,
              "rounded-md":
                this.appearance !== "squared" &&
                this.appearance !== "pill" &&
                this.legacy,
              "font-weight-thin": this.weight === "thin" && this.legacy,
              "btn--pill": this.appearance === "pill" && this.legacy,
              flex: this.withIcon && this.legacy,
              "flex-row": this.withIcon && this.legacy,
              "items-center": this.withIcon && this.legacy,
              "min-w-[100%] sm:min-w-[345px]":
                this.$props.width === "consistent",
              "max-w-[345px]": this.$props.width === "consistent",
              "w-full": this.$props.width === "full",
              shrink: this.shrink,
              "btn--no-background": this.noBackground,
            }
          : {}),
        disabled: this.disabled,
        uppercase: this.uppercase,
      };
    },
    attrs() {
      const isButton = this.tag === "button" && !this.path;
      const isLink = !!this.path;

      return {
        type: isButton ? this.type : null,
        disabled: isButton ? this.disabled : null,
        to: isLink ? this.path : null,
        external: isLink ? this.external : null,
      };
    },
  },
  methods: {
    fromToolstationCdnUrl,

    clicked(event: Event) {
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }

      // @deprecated use normal @click
      this.$emit("clickAction", event);

      this.$emit("click", event);
    },
  },
});
