import { fetchMonetateOmniJson } from "~/services/monetate.service";
import { FeatureFlagsMutation } from "~/store/featureFlags";

// Fetch Monetate omni JSON flag and merge into feature flag store

export default async function (context) {
  const { route, store } = context;
  const path = `${store.getters.domain}${route.path}`;

  // Fetch Monetate omni json
  return await fetchMonetateOmniJson(context, path)
    .then((res) => {
      if (!Array.isArray(res)) {
        res = [res];
      }

      res.forEach((monetateFlag) => {
        if (Object.keys(monetateFlag).length !== 0) {
          // Merge Monetate flags into store
          store.commit(
            `featureFlags/${FeatureFlagsMutation.SetFlags}`,
            monetateFlag
          );
        }
      });
    })
    .catch((err) =>
      console.log("Monetate omniJson unable to retrieve data", err)
    );
}
