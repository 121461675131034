import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { concat, difference, groupBy, map, pluck, prop } from "ramda";
import { getProductStock } from "./api/stock.api";
import type { ProductStockResponse } from "./api/stock.api.d";
import { indexStockBySite } from "~/lib/products/transformers";
import { getProductByCode, getProducts } from "~/services/api/products.api";
import { FetchProductsPayload } from "~/services/api/products.api.d";
import { ProductStock } from "~/services/products.service.d";
import { Product } from "~/types/product";
import { ProductWithStock } from "~/types/products";
import { get } from "~/utils/lru-cache";
import { camelizeKeys } from "~/utils/utils";

export enum ProductChannel {
  OnlineAndTradeCounter = 0,
  OnlineOnly = 1,
  TradeCounterOnly = 2,
  DirectShipOnly = 3,
  OnlineAndTradeCounterNextDay = 4,
  Discontinued = 5,
}

export const fetchStock = async (
  $axios: NuxtAxiosInstance,
  products: string[],
  sites: string[]
): Promise<ProductStock[]> =>
  await getProductStock($axios, { products, sites })
    .then((response) => response.data)
    .then((stock: ProductStockResponse[]) => {
      return concat(
        // Fill in any blanks that do not come from the API
        difference(products, pluck("product_code", stock)).map(
          (pid) =>
            ({
              product_code: pid,
              stock_qty: "0",
            }) as ProductStockResponse
        ),

        stock
      );
    })
    .then(camelizeKeys);

/**
 * Fetch a single product by code Cache the response
 * @param $axios
 * @param productCode
 */
export const fetchProduct = async (
  $axios: NuxtAxiosInstance,
  productCode: string
): Promise<Product> =>
  await get(`fetchProduct.${productCode}`, async (): Promise<Product> => {
    return await getProductByCode($axios, productCode)
      .then((response) => {
        if (!response.data?.name) return Error("Product not found");
        return response.data;
      })
      .then(camelizeKeys);
  });

/**
 * Fetch more than one product in a single request
 * Not cached
 *
 * @param $axios
 * @param payload
 */
export const fetchProducts = async (
  $axios: NuxtAxiosInstance,
  payload: FetchProductsPayload
): Promise<Product[]> => await getProducts($axios, payload).then(camelizeKeys);

/**
 * Fetches a single product to map over it's related variations
 * @param $axios
 * @param productCode
 * @param sites
 * @returns
 */
export const fetchVariations = async (
  $axios: NuxtAxiosInstance,
  productCode: string,
  sites: string[] = ["WW"]
): Promise<ProductWithStock[]> => {
  return await Promise.all(
    await fetchProduct($axios, productCode)
      .then(async (product: Product) => {
        return await fetchProducts($axios, {
          products: [...(product.variations ?? []), productCode],
        });
      })
      .then(async (products: Product[] | null) => {
        if (products === null) {
          return [];
        }

        const stock = groupBy(
          prop("productCode"),
          await fetchStock($axios, map(String, pluck("code", products)), sites)
        );

        return products.map((product: Product) => ({
          ...product,
          stock: indexStockBySite(stock[product.code] ?? []),
        }));
      })
  );
};

/**
 * Helpers
 */
export const productSlug = (product: Product): string =>
  `/${product.slug}/p${product.code}`;
