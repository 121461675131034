import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { AddAccountCustomerPayload } from "../account.service";
import { AccountCustomer } from "../account.service.d";
import { UpdateSpendingLimitsPayload } from "./tradeAccount.api.d";
import { wrapError } from ".";
import { ApiOrderResponse } from "~/services/api/orders.api.d";
import { UpdateSpendingLimitsPayloadValidationErrors } from "~/services/trade-account.d";
import { Account } from "~/store/auth";

const ENDPOINTS = {
  ACCOUNT: (accountId: string) => `/accounts/${accountId}`,
  ACCOUNT_CUSTOMERS: (accountId: string) =>
    `/accounts/${accountId}/customers?sort=lastname`,
  ACCOUNT_CUSTOMER: (accountId: string, customerId: string) =>
    `/accounts/${accountId}/customers/${customerId}`,
  ACCOUNT_CUSTOMER_LIMITS_BULK: (accountId: Account["id"]) =>
    `/accounts/${accountId}/customer-limits`,

  ACCOUNT_CUSTOMER_SPEND_STATS: (
    accountId: Account["id"],
    customerId: AccountCustomer["id"]
  ) => `/accounts/${accountId}/customers/${customerId}/spend-stats`,
  ACCOUNT_ORDERS: (accountId: number) => `/accounts/${accountId}/orders`,
  BR_TA_PROMO_DOCUMENT: "/promotions/trade-account/promos",
  BR_TA_FAQ_DOCUMENT: "/faqs/trade-account/faqs",
};

export const getAccount = async (
  $axios: NuxtAxiosInstance,
  accountId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.ACCOUNT(accountId));

export const getAccountCustomers = async (
  $axios: NuxtAxiosInstance,
  accountId: string
): Promise<AxiosResponse<AccountCustomer[]>> =>
  await $axios.$get(ENDPOINTS.ACCOUNT_CUSTOMERS(accountId));

export const createAccountCustomer = async (
  $axios: NuxtAxiosInstance,
  accountId: string,
  data: AddAccountCustomerPayload
): Promise<AxiosResponse<void>> =>
  await $axios.$post(ENDPOINTS.ACCOUNT_CUSTOMERS(accountId), data);

export const deleteAccountCustomer = async (
  $axios: NuxtAxiosInstance,
  accountId: string,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.ACCOUNT_CUSTOMER(accountId, customerId));

export const getAccountOrders = async (
  $axios: NuxtAxiosInstance,
  accountId: number,
  page: number = 1,
  pageLimit: number = 50
): Promise<ApiOrderResponse> => {
  const querystring = new URLSearchParams();
  querystring.set("page", String(page));
  querystring.set("limit", String(pageLimit));

  return await $axios.$get(
    ENDPOINTS.ACCOUNT_ORDERS(accountId) + "?" + querystring.toString()
  );
};

export const updateSpendingLimits = (
  $axios: NuxtAxiosInstance,
  accountId: Account["id"],
  payload: UpdateSpendingLimitsPayload
) =>
  wrapError<
    AxiosResponse<any>,
    AxiosResponse<UpdateSpendingLimitsPayloadValidationErrors>
  >($axios.$patch(ENDPOINTS.ACCOUNT_CUSTOMER_LIMITS_BULK(accountId), payload));

export const getAccountCustomerSpendStats = <
  T extends Pick<
    AccountCustomer,
    | "id"
    | "employeeOrderSpendLimit"
    | "employeeMonthlySpendLimit"
    | "employeePurchaseOrderRequired"
  >,
>(
  $axios: NuxtAxiosInstance,
  accountId: Account["id"],
  cardholder: T
): Promise<
  AxiosResponse<{ this_month: { spend: string; remaining_balance: string } }>
> =>
  $axios
    .$get(ENDPOINTS.ACCOUNT_CUSTOMER_SPEND_STATS(accountId, cardholder.id))
    .catch(() => null);

export const getBrAccountPromoDocuments = async (brContentURL: string) => {
  const url = `${brContentURL}${ENDPOINTS.BR_TA_PROMO_DOCUMENT}`;
  const response = await fetch(url);
  return response.json();
};

export const getBrAccountFaqDocuments = async (brContentURL: string) => {
  const url = `${brContentURL}${ENDPOINTS.BR_TA_FAQ_DOCUMENT}`;
  const response = await fetch(url);
  return response.json();
};
