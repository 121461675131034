
import Vue from "vue";
import { mapGetters } from "vuex";
import { procardConfig } from "~/services/config.service";
import MenuItem from "~/components/account/MenuItem.vue";
import {
  ToolstationIcon,
  CardOutlineIcon,
  ListIcon,
  LogoutIcon,
  PencilIcon,
  QrIcon,
  SavedListIcon,
} from "~/components/UI/icons";

export default Vue.extend({
  name: "AccountMenu",

  components: {
    ToolstationIcon,
    CardOutlineIcon,
    ListIcon,
    LogoutIcon,
    PencilIcon,
    QrIcon,
    SavedListIcon,
    MenuItem,
  },

  data() {
    return {
      accountMenuItems: [],
    };
  },

  async fetch() {
    const configProcard = await procardConfig(this.$config.localeInstance);

    const menuItems = [
      {
        url: "/account/details/manage",
        text: "account.details.pageTitle",
        icon: "pencil-icon",
      },
      {
        url: "/account/order-history",
        text: "account.orderHistory.pageTitle",
        icon: "list-icon",
      },
      {
        url: "/account/saved-lists",
        text: "account.savedLists.pageTitle",
        icon: "saved-list-icon",
      },
    ] as any;

    if (this.isLoyaltyClubAccount === true) {
      menuItems.unshift({
        url: "/account/club",
        text: "account.club.pageTitle",
        icon: "toolstation-icon",
      });
    }

    if (
      configProcard.enabled === true &&
      configProcard.application.enabled === true &&
      this.getUser?.accountType === null
    ) {
      menuItems.push({
        url: "/pro-card/application",
        text: "account.procard.register",
        icon: "card-outline-icon",
      });
    }

    if (
      configProcard.enabled === true &&
      this.getUser?.accountType === "Pro Card"
    ) {
      menuItems.push({
        url: "/account/pro-card",
        text: "account.procard.account",
        icon: ["fas", "list"],
      });
    }

    menuItems.push({
      url: "/",
      text: "account.logout",
      icon: "logout-icon",
      eventName: "logoutEvent",
    });

    this.accountMenuItems = menuItems;
  },

  computed: {
    ...mapGetters("auth", ["getUser", "isLoyaltyClubAccount"]),
  },

  methods: {
    handleFunctionCall(function_name: string): any {
      (this as unknown as { [key: string]: () => void })[function_name]();
    },

    logoutEvent() {
      window.location.href = "/logout";
    },

    isActive(item: any) {
      if (item.eventName) return false;
      return this.$route.path.startsWith(
        item.url?.replace(/^(\/.+\/.+)\/.+$/, "$1")
      );
    },
  },
});
