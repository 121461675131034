import { render, staticRenderFns } from "./NavbarBottom.vue?vue&type=template&id=fd57f3e0&scoped=true"
import script from "./NavbarBottom.vue?vue&type=script&lang=ts"
export * from "./NavbarBottom.vue?vue&type=script&lang=ts"
import style0 from "./NavbarBottom.vue?vue&type=style&index=0&id=fd57f3e0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd57f3e0",
  null
  
)

export default component.exports