import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { always } from "ramda";
import { LanguageCode } from "~/types/iso-639-1";
import { LocaleCode, splitLocale } from "~/utils/i18n";
import { PageItem } from "~/services/content.service.d";
import {
  getMenu,
  getPageBySlug,
  getRedirects,
} from "~/services/api/content.api";
import {
  ApiMenuItem,
  ApiPagesResponse,
  MenuItem,
} from "~/services/api/content.api.d";
import { get } from "~/utils/lru-cache";

export enum MenuId {
  FooterHelpContact = "footer-help-contact",
  FooterCompanyInfo = "footer-company-information",
  FooterPolicies = "footer-policies",
  SupportServices = "support-services",
  NewsMenu = "news-menu",
}

// #region Private Methods
const getContentMenu = async (
  $axios: NuxtAxiosInstance,
  menuId: string,
  languageCode?: LanguageCode
): Promise<MenuItem[]> => {
  return await getMenu($axios, menuId, languageCode).then((response) => {
    return response.data.items.map((item: ApiMenuItem) => ({
      url: item.url,
      webUrl: item.web_url,
      title: item.title,
      id: item.id,
    }));
  });
};

const mapPageApiDataToPageItem = (res: ApiPagesResponse): PageItem => {
  return {
    id: res.data.id,
    date: res.data.date,
    slug: res.data.slug,
    title: res.data.title.rendered,
    content: res.data.content.rendered,
    excerpt: res.data.excerpt.rendered,
    meta: res.data.meta,
  };
};

const getLocalisedContentMenu = async (
  $axios: NuxtAxiosInstance,
  menuId: string,
  locale: LocaleCode,
  retryWithoutLocale: boolean = false
): Promise<MenuItem[]> => {
  return await get(`getLocalisedContentMenu.${menuId}.${locale}`, async () => {
    const menuItems = await getContentMenu(
      $axios,
      menuId,
      splitLocale(locale).languageCode
    );

    // If no content => Try again without the locale
    if (menuItems == null && retryWithoutLocale) {
      return await getContentMenu($axios, menuId);
    }

    return menuItems;
  });
};
// #endregion

// #region Public Methods
export const fetchPageArticle = async (
  $axios: NuxtAxiosInstance,
  slug: string
): Promise<PageItem> => {
  return await get(`fetchPageArticle.${slug}`, async () => {
    return await getPageBySlug($axios, slug).then(mapPageApiDataToPageItem);
  });
};

export const fetchTradeBrands = async (
  $axios: NuxtAxiosInstance
): Promise<ApiPagesResponse> => {
  return await get("fetchTradeBrands", async () => {
    return await getPageBySlug($axios, "top-brand-menu");
  });
};

export const getCollectionDetailsContent = async (
  $axios: NuxtAxiosInstance
): Promise<PageItem> =>
  await fetchPageArticle($axios, "trolley-click-collect-details");

export const getMenuForFooterCompanyInformation = async (
  $axios: NuxtAxiosInstance,
  locale: LocaleCode
): Promise<MenuItem[]> =>
  await getLocalisedContentMenu($axios, MenuId.FooterCompanyInfo, locale);

export const getMenuForSupportServices = async (
  $axios: NuxtAxiosInstance,
  locale: LocaleCode
): Promise<MenuItem[]> =>
  await getLocalisedContentMenu($axios, MenuId.SupportServices, locale);

export const getSiteRedirects = async (
  $axios: NuxtAxiosInstance
): Promise<any> => {
  return await get("siteRedirects", async () => {
    return await getRedirects($axios).catch(always([]));
  });
};
// #endregion
