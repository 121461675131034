import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  compose,
  filter,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  map,
  sortBy,
} from "ramda";
import {
  getTaxonomiesTopLevel,
  getTaxonomyHierarchy,
  getTaxonomyById,
} from "./api/taxonomies.api";
import type {
  SeeAlsoItem,
  TaxonomyData,
  TaxonomyDataApi,
  TaxonomyNote,
} from "./taxonomies.d";
import { Department } from "~/components/layout/header/components/Departments.d";
import { handleError } from "~/services/helpers";
import { get } from "~/utils/lru-cache";
import { camelizeKeys } from "~/utils/utils";

type FilterHiddenAndSort = (department: Department[]) => Department[];
const filterHiddenAndSort: FilterHiddenAndSort = compose<
  Department[][],
  Department[],
  Department[],
  Department[]
>(
  map(
    ifElse(
      (d) => d?.children?.length > 0,
      (d) => ({ ...d, children: filterHiddenAndSort(d.children) }),
      identity
    )
  ),
  sortBy((d) => d?.order ?? 0),
  filter<Department>((d) => (d?.menu ?? 1) === 1)
);

export const fetchTopLevelDepartmentsHierarchy = async (
  $axios: NuxtAxiosInstance
): Promise<Department[] | null> =>
  await get(`fetchTopLevelDepartmentsHierarchy`, async () => {
    try {
      return filterHiddenAndSort(await getTaxonomiesTopLevel($axios));
    } catch (error) {
      handleError(error);
      return null;
    }
  });

export const fetchDepartmentsHierarchy = async (
  $axios: NuxtAxiosInstance
): Promise<Department[] | null> =>
  await get(`fetchDepartmentsHierarchy`, async () => {
    try {
      return filterHiddenAndSort(await getTaxonomyHierarchy($axios));
    } catch (error) {
      handleError(error);
      return null;
    }
  });

const mapTaxonomyData = (data: TaxonomyDataApi): TaxonomyData => ({
  ancestors: !isNil(data.ancestors)
    ? data.ancestors.map((ancestor) => mapTaxonomyData(ancestor))
    : [],
  menu: data.menu,
  order: data.order,
  parentId: data.parent_id,
  taxonomy: data.taxonomy,
  children: !isNil(data.children)
    ? data.children.map((child) => {
        return {
          menu: child.menu,
          order: child.order,
          parentId: child.parent_id,
          taxonomy: {
            slug: child.taxonomy.slug,
            id: child.taxonomy.id,
            name: child.taxonomy.name,
            image: child.taxonomy.image,
          },
          children: !isNil(child?.children)
            ? child?.children.map((subChild) => {
                return {
                  menu: subChild.menu,
                  order: subChild.order,
                  parentId: subChild.parent_id,
                  taxonomy: {
                    slug: subChild.taxonomy.slug,
                    id: subChild.taxonomy.id,
                    name: subChild.taxonomy.name,
                  },
                };
              })
            : [],
        };
      })
    : [],

  // TODO: Keeping this here for now for reference in-case of any issues
  // ancestors: !isNil(data.ancestors)
  //   ? data.ancestors.map((ancestor) => mapTaxonomyData(ancestor))
  //   : [],
  // menu: data.menu,
  // children: !isNil(data.children)
  //   ? data.children.map((child) => mapTaxonomyData(child))
  //   : [],
  // order: data.order,
  // parentId: data.parent_id,
  // taxonomy: data.taxonomy,
});

export const fetchTaxonomyById = async (
  $axios: NuxtAxiosInstance,
  catId: number
): Promise<any> =>
  await get(`fetchTaxonomy.${catId}`, async () => {
    try {
      return await getTaxonomyById($axios, catId).then((result) => {
        if (result?.data) {
          return head([
            mapTaxonomyData(result.data) as Department,
          ]) as TaxonomyData;
        }
      });
    } catch (error) {
      return handleError(error);
    }
  });

const populateSeeAlsoWithTaxonomy = async (
  $axios: NuxtAxiosInstance,
  seeAlsoItems: SeeAlsoItem[]
): Promise<SeeAlsoItem[] | null> => {
  const seeAlsoTaxonomyRequests = seeAlsoItems.map(
    async (seeAlso: SeeAlsoItem) =>
      await fetchTaxonomyById($axios, Number(seeAlso.departmentId))
  );
  const taxonomies = (await Promise.all(
    seeAlsoTaxonomyRequests
  )) as TaxonomyData[];

  return seeAlsoItems.map((seeAlso: SeeAlsoItem) => {
    const taxonomy = taxonomies.find((t) =>
      !isNil(t) ? t.taxonomy.id === seeAlso.departmentId : null
    );
    return {
      ...seeAlso,
      ...(!isNil(taxonomy) ? { department: taxonomy } : {}),
    };
  });
};

export const getSeeAlsoItems = async (
  $axios: NuxtAxiosInstance,
  t: TaxonomyData
): Promise<SeeAlsoItem[] | null> => {
  const seeAlsoKey = "see-also";

  if (t?.taxonomy?.notes == null) return null;
  const seeAlsoNoteJson =
    t.taxonomy.notes.find((n: TaxonomyNote) => n.name === seeAlsoKey)?.value ??
    "";
  if (isEmpty(seeAlsoNoteJson) || isNil(seeAlsoNoteJson)) return null;

  let seeAlsoItems = [];
  try {
    seeAlsoItems = camelizeKeys(await JSON.parse(seeAlsoNoteJson));
  } catch (e) {
    return null;
  }

  return await populateSeeAlsoWithTaxonomy($axios, seeAlsoItems);
};

export default { fetchDepartmentsHierarchy, fetchTaxonomyById };
