import { isNil, pluck, uniq } from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex/types";
import { TrackingEvents } from "./tracking";
import { RootState } from "./index";
import { Stores } from "~/constants/ecomApi";
import {
  anyStockForProductCode,
  stockForProductCodeAndSite,
} from "~/lib/stock";
import { fetchStock } from "~/services/products.service";
import { ProductStock } from "~/services/products.service.d";
import type { AppWindow } from "~/types/";

interface StockState {
  stockLevels: ProductStock[];
  products: String[];
}

export const state = (): StockState => ({
  stockLevels: [],
  products: [] as string[],
});

export const mutations: MutationTree<StockState> = {
  setStock: (state: StockState, payload: ProductStock[]) => {
    state.stockLevels = payload;
  },
  appendStock: (state: StockState, payload: ProductStock[]) => {
    const stock = state.stockLevels.concat(payload);

    // Remove duplicate stock objects
    state.stockLevels = stock.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (stockLevel) =>
            stockLevel.siteId === value.siteId &&
            stockLevel.productCode === value.productCode
        )
    );
  },
  showSelectedBranchStock: (state: StockState, selectedBranch: string) => {
    state.stockLevels = state.stockLevels.filter(
      (productStock) =>
        productStock.siteId === selectedBranch ||
        productStock.siteId === Stores.Web
    );
  },
  setProducts: (state: StockState, payload: string[]) => {
    state.products = payload;
  },
};

export const getters: GetterTree<StockState, RootState> = {
  getStock: (state: StockState) => state.stockLevels ?? [],
  getProductCodes: (state: StockState) =>
    pluck("productCode", state.stockLevels) ?? [],
  getProducts: (state: StockState) => state.products,

  hasStockForDelivery: (state: StockState) => (code: string) =>
    (stockForProductCodeAndSite(state.stockLevels, code, Stores.Web)
      ?.stockQty ?? "0") !== "0",

  hasStockForSite: (state: StockState) => (code: string, siteId: string) =>
    (stockForProductCodeAndSite(state.stockLevels, code, siteId)?.stockQty ??
      "0") !== "0",

  hasAnyStock: (state: StockState) => (code: string) =>
    anyStockForProductCode(state.stockLevels, code),

  stockForDelivery: (state: StockState) => (code: string) =>
    stockForProductCodeAndSite(state.stockLevels, code, Stores.Web)?.stockQty ??
    "0",

  stockForCollection: (state: StockState) => (code: string, siteId: string) =>
    stockForProductCodeAndSite(state.stockLevels, code, siteId)?.stockQty ??
    "0",

  stockForNextDayCollection:
    (state: StockState) => (code: string, siteId: string) => {
      const stockForCollection = stockForProductCodeAndSite(
        state.stockLevels,
        code,
        siteId
      )?.stockQty;

      if (stockForCollection === "0") {
        const stockForDelivery = stockForProductCodeAndSite(
          state.stockLevels,
          code,
          Stores.Web
        )?.stockQty;

        if (stockForDelivery !== "0") {
          return stockForDelivery;
        }

        return "0";
      }

      return "0";
    },
};

export const actions: ActionTree<StockState, RootState> = {
  // @todo remove calls to updateStockAction so we're not firing custom events in this store
  async getStock({ commit, state, rootGetters }, { products, axios }) {
    const fetchStockForProducts = products.concat(state.products);
    axios = axios || this.$axios;

    const stock = await fetchStock(axios, fetchStockForProducts, [
      Stores.Web,
      rootGetters["branch/selectedBranchId"],
    ]).catch(() => []);

    commit("appendStock", stock);
  },

  async getStockForSites({ commit, state }, { products, sites, axios }) {
    const fetchStockForProducts = products.concat(state.products);

    const stock = await fetchStock(axios, fetchStockForProducts, sites).catch(
      () => []
    );
    commit("appendStock", stock);
  },

  async updateStockAction({ commit, rootGetters }, { products }) {
    const sites = ["WW"];

    if (!isNil(rootGetters["branch/selectedBranchId"])) {
      sites.push(rootGetters["branch/selectedBranchId"]);
    }

    const stock = await fetchStock(this.$axios, products, sites).catch(
      () => []
    );

    if (stock.length) {
      commit("setStock", stock);

      // this part needs to be rethinked
      // ---------------------------------
      // legacy value for stock checking against trolley calls,
      // once the trolley has been igrated we can drop this
      if (process.client) {
        (window as AppWindow).tscStockCheck = stock;

        // Marketing require this event for a/b tests
        let event: CustomEvent;
        if (
          !isNil(window.CustomEvent) &&
          typeof window.CustomEvent === "function"
        ) {
          event = new window.CustomEvent("stock-event", { detail: stock });
        } else {
          event = document.createEvent("CustomEvent");
          event.initCustomEvent("stock-event", true, true, {
            detail: stock,
          });
        }

        document.body.dispatchEvent(event);

        commit(
          "tracking/addTrackingEvent",
          {
            type: TrackingEvents.StockLoaded,
            data: { stock },
          },
          { root: true }
        );
      }
    }
  },
  additionalStockLookup({ dispatch, commit, getters }, { products }) {
    dispatch("getStock", {
      products: uniq([...products, ...getters.getProductCodes]),
    });
  },

  setStockProductCodes({ commit }, { products }) {
    commit("setProducts", products);
  },

  teardown({ commit }) {
    commit("setProducts", []);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
