export const regexPagePatterns = {
  home: /toolstation.com($|\/$|\/\?)/,
  category: /.*\/c\d{1,4}/,
  search: /q=([^&]*)/,
  productPage: /(p)([a-zA-Z0-9]{5})/,
};

export function pageRegexExpression(type) {
  return new RegExp(regexPagePatterns[type], "g");
}

export const pageType = (url) => {
  switch (true) {
    case pageRegexExpression("home").test(url):
      return "home";
    case pageRegexExpression("category").test(url):
      return "taxonomy";
    case pageRegexExpression("search").test(url):
      return "search";
    case pageRegexExpression("productPage").test(url):
      return "product";
    default:
      return "other";
  }
};
