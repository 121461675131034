<template>
  <div
    class="carousel-wrapper"
    :class="{ 'carousel-wrapper--dark': whiteDots }"
  >
    <SsrCarousel v-bind="settings" v-model="page" @change="onSlideChange">
      <slot name="slides"></slot>
      <template #back-arrow><slot name="backArrow"></slot></template>
      <template #next-arrow><slot name="nextArrow"></slot></template>
    </SsrCarousel>
  </div>
</template>

<script>
export default {
  name: "BaseCarousel",
  props: {
    settings: {
      type: Object,
      required: true,
    },
    autoPlay: {
      type: Object,
      required: false,
      default: null,
    },
    whiteDots: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    if (this.autoPlay) {
      const maxWidth = this.autoPlay.maxWidth; // Autoplay to stop at this width

      if ((maxWidth && window.innerWidth < maxWidth) || !maxWidth) {
        this.toggleAutoplay(true);
      }

      if (maxWidth) {
        window.addEventListener("resize", this.isAutoPlay);
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.isAutoPlay);
  },
  methods: {
    isAutoPlay() {
      window.innerWidth > this.autoPlay.maxWidth
        ? this.toggleAutoplay(false)
        : this.toggleAutoplay(true);
    },
    // Carousel library does not allow autoplay to be toggled for different widths, implementing manually here (https://www.npmjs.com/package/vue-ssr-carousel)
    toggleAutoplay(val) {
      if (val && !window.uspBarInterval) {
        window.uspBarInterval = setInterval(() => {
          this.page === this.autoPlay.itemCount
            ? (this.page = 1)
            : (this.page += 1);
        }, 3000);
      }
      if (!val && window.uspBarInterval) {
        clearInterval(window.uspBarInterval);
        window.uspBarInterval = null;
      }
    },

    onSlideChange(currentSlide) {
      this.$emit("slideChange", currentSlide);
    },
  },
};
</script>

<style lang="postcss" scoped>
.carousel-wrapper {
  @apply w-full;

  &--dark {
    :deep(.ssr-carousel-dot-icon) {
      @apply bg-white border-white;
    }
  }
}

:deep(.ssr-carousel-arrows) {
  @apply opacity-0 duration-200 ease-in-out;
}

:deep(.ssr-carousel-slides:hover) {
  .ssr-carousel-arrows {
    @apply opacity-100;
  }
}
:deep(.ssr-carousel-dots) {
  @apply mt-2 mb-2;
}

:deep([disabled] > .ssr-carousel-dot-icon) {
  @apply bg-blue scale-125;
}

:deep([aria-disabled="true"] > .ssr-carousel-dot-icon) {
  @apply bg-blue scale-125;
}

:deep(.ssr-carousel-dot-icon) {
  @apply bg-grey-4 border-none w-[8px] h-[8px] opacity-70 ease-in-out duration-200;
}
</style>
