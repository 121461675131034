const middleware = {}

middleware['isGuestEmailAvailable'] = require('../middleware/isGuestEmailAvailable.ts')
middleware['isGuestEmailAvailable'] = middleware['isGuestEmailAvailable'].default || middleware['isGuestEmailAvailable']

middleware['isLoggedIn'] = require('../middleware/isLoggedIn.ts')
middleware['isLoggedIn'] = middleware['isLoggedIn'].default || middleware['isLoggedIn']

middleware['isLoggedInOrGuest'] = require('../middleware/isLoggedInOrGuest.ts')
middleware['isLoggedInOrGuest'] = middleware['isLoggedInOrGuest'].default || middleware['isLoggedInOrGuest']

middleware['isTradeCustomer'] = require('../middleware/isTradeCustomer.ts')
middleware['isTradeCustomer'] = middleware['isTradeCustomer'].default || middleware['isTradeCustomer']

middleware['mustHaveTrolleyItems'] = require('../middleware/mustHaveTrolleyItems.ts')
middleware['mustHaveTrolleyItems'] = middleware['mustHaveTrolleyItems'].default || middleware['mustHaveTrolleyItems']

middleware['omniJsonFlags'] = require('../middleware/omniJsonFlags.js')
middleware['omniJsonFlags'] = middleware['omniJsonFlags'].default || middleware['omniJsonFlags']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['tsSessionId'] = require('../middleware/tsSessionId.js')
middleware['tsSessionId'] = middleware['tsSessionId'].default || middleware['tsSessionId']

export default middleware
