import Vue from "vue";
import { mapGetters } from "vuex";
import tidyImgUrl from "~/utils/tidyImageUrl";
import { currencySymbol } from "~/utils/localeHelpers";
import { getSavingsBadgeLines } from "~/lib/product";
import { getRecommendations } from "~/services/search.service";
import {
  getWidgetType,
  getWidgetParams,
} from "~/utils/bloomreach/pathwaysAndRecs";

interface ProductCompound {
  productid: string;
}

interface CategoryCompound {
  categoryid: string;
  contentType: string;
  displayName: string;
  name: string;
}

interface SelectionValues {
  label: string;
  value: string;
}

interface WidgetCompound {
  contentType: string;
  displayName: string;
  name: string;
  widgetalgo: {
    selectionValues: SelectionValues[];
  };
  widgetid: string;
}

interface Pathways {
  categoryCompound: CategoryCompound;
  contentType: string;
  displayName: string;
  keyword: string;
  name: string;
  productCompound: ProductCompound[];
  widgetCompound: WidgetCompound;
}

interface ProductData {
  assettr: string;
  brand: string;
  channel: number;
  description: string;
  formattedPrices: string;
  nameQty: string;
  nameType: string;
  pid: string;
  price: number;
  prices: string;
  salePrice: number;
  thumbImage: string;
  title: string;
  url: string;
}

export default Vue.extend({
  computed: {
    ...mapGetters("search", ["getBrandUrl"]),
  },
  methods: {
    async getPathwaysAndRecs(pathways: Pathways): Promise<any> {
      const { widgetCompound, categoryCompound, productCompound, keyword } =
        pathways;

      const productIds: any = productCompound
        .map((product: ProductCompound) => product.productid.split(";")[0])
        .map((product: string) => product.split("=")[1])
        .join(",")
        .trim();

      const widgetId = widgetCompound.widgetid;

      const widgetType = getWidgetType(
        widgetCompound.widgetalgo.selectionValues[0].label
      );

      const widgetParams = getWidgetParams(
        widgetType,
        categoryCompound?.categoryid,
        productIds,
        keyword
      );

      return await getRecommendations(this, widgetType, widgetId, widgetParams);
    },
    getBadgeUrl(modifier: string, type: string) {
      return modifier
        ? `${this.getBrandUrl}${type}-img-${tidyImgUrl(modifier)}.png`
        : "";
    },
    getPrice(productData: ProductData, type: string) {
      return JSON.parse(productData.prices)[type];
    },
    getSavingValue(recommendation: ProductData) {
      const gross = this.getPrice(recommendation, "gross") ?? null;
      const was = this.getPrice(recommendation, "was") ?? null;

      return getSavingsBadgeLines(
        {
          gross,
          was,
          net: null,
          priceperunit: null,
        },
        currencySymbol(this.$i18n.locale, this.$config.currency),
        this.$tc("search.priceSave"),
        this.$tc("search.priceOff")
      );
    },
  },
});
